import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ProgressBar } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { setPropTypes } from 'recompose'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import _ from 'lodash'
import { Micons } from '../../icons'
import './style.css';



const NavLink = (props) => <li><Link className={props.className} to={props.to}>{props.children}</Link></li>;
const NavLinkAnchor = (props) => <li><button type='button' {...props}>{props.children}</button></li>;


class WizardNaviation extends Component {
  state = { showProfileModal: false };

  linkIsActive(current){
    const fieldsExist = (fields, cb) => user => {
        return [ ...fields.filter(field => {
            const val = user[field]
            if (val === undefined) return true
            if (typeof val === 'string') return !val.length
            if (Array.isArray(val)) return !val.length
            return false
        }), ...(cb ? cb(user) : []) ]
    }
    const validation = {
        'welcome': fieldsExist([ 'firstName', 'lastName' ]),
        'consulting': fieldsExist([ 'current_ic', 'billable_work' ], user => {
            let broke = []
            if (user.current_ic) {
                if (user.how_long_ic === undefined) broke.push('how_long_ic')
            } else {
                if (user.considering_ic === undefined) broke.push('considering_ic')
                if (user.fulltime_interest === undefined) broke.push('fulltime_interest')
            }
            if (user.billable_work) {
                if (!user.consulting || !user.consulting.length) broke.push('consulting')
                if (user.how_long_bc === undefined) broke.push('how_long_bc')
            }
            return broke
        }),
        'corporate': fieldsExist(['corporate_experience'], user => {
            let broke = []
            if (user.corporate_experience) {
                broke = [ ...broke, ...fieldsExist(['company_size', 'internal_consulting', 'leadership_dev_prog'])(user) ]
            }
            return broke
        }),
        'expertise': fieldsExist(['primary_experience', 'secondary_experience', 'technology_experience']),
        'experience': fieldsExist(['industry_experience', 'functional_experience', 'contextual_experience']),
        'availability': fieldsExist(['travel', 'on_site', 'full_time'], user => {
            let broke = []
            if (!user.full_time) {
                if (user.part_time === undefined) broke.push('part_time')
                if (!user.part_time) {
                    if (user.available_date === undefined) broke.push('available_date')
                    else if (!user.available_date.length) broke.push('available_date')
                }
            }
            return broke
        }),
        'background': fieldsExist(['travel', 'on_site', 'full_time'], user => {
          let broke = []
          if (!user.full_time) {
              if (user.part_time === undefined) broke.push('part_time')
              if (!user.part_time) {
                  if (user.available_date === undefined) broke.push('available_date')
                  else if (!user.available_date.length) broke.push('available_date')
              }
          }
          return broke
        })
    }
    var brokeFields = 0
    switch(current){
        case 'consulting':
            brokeFields += validation['welcome'](this.props.userData).length
            break
        case 'corporate':
            brokeFields += validation['welcome'](this.props.userData).length
            brokeFields += validation['consulting'](this.props.userData).length
            break
        case 'expertise':
            brokeFields += validation['welcome'](this.props.userData).length
            brokeFields += validation['consulting'](this.props.userData).length
            brokeFields += validation['corporate'](this.props.userData).length
            break
        case 'experience':
            brokeFields += validation['welcome'](this.props.userData).length
            brokeFields += validation['consulting'](this.props.userData).length
            brokeFields += validation['corporate'](this.props.userData).length
            brokeFields += validation['expertise'](this.props.userData).length
            break
        case 'availability':
            brokeFields += validation['welcome'](this.props.userData).length
            brokeFields += validation['consulting'](this.props.userData).length
            brokeFields += validation['corporate'](this.props.userData).length
            brokeFields += validation['expertise'](this.props.userData).length
            brokeFields += validation['experience'](this.props.userData).length
            break
        case 'background':
            brokeFields += validation['welcome'](this.props.userData).length
            brokeFields += validation['consulting'](this.props.userData).length
            brokeFields += validation['corporate'](this.props.userData).length
            brokeFields += validation['expertise'](this.props.userData).length
            brokeFields += validation['experience'](this.props.userData).length
            brokeFields += validation['availability'](this.props.userData).length
            break
        default:
    }
    return brokeFields
  }

  render() {
    const { active, className } = this.props
    let progress = 3
    const complete = (active) => {
      switch(active){
        case 'welcome':
          return 3
        case 'consulting':
          return 17
        case 'corporate':
          return 33
        case 'expertise':
          return 49
        case 'experience':
          return 65
        case 'availability':
          return 82
        case 'background':
          return 98
        case 'thankyou':
          return 100
        default:
          return 3
      }
    }

    return(
      <div style={{textAlign: 'center'}} className={className}>
        <div className='wizard-nav'>
          <NavLink to='/signup/welcome' eventKey={1} className={active === 'welcome' ? 'active' : ''}>
            <Micons name="welcome-hands" />
            <span className="nav-item-label">Welcome</span>
          </NavLink>
          <NavLink to={this.linkIsActive('consulting') === 0 ? '/signup/consulting' : '#'} eventKey={2} className={active === 'consulting' ? 'active consulting' : 'consulting'}>
            <Micons name="concentric-circles" />
            <span className="nav-item-label">Consulting</span>
          </NavLink>
          <NavLink to={this.linkIsActive('corporate') === 0 ? '/signup/corporate' : '#'} eventKey={3} className={active === 'corporate' ? 'active' : ''}>
          <Micons name="building" />
            <span className="nav-item-label">Corporate</span>
          </NavLink>
          <NavLink to={this.linkIsActive('expertise') === 0 ? '/signup/expertise' : '#'} eventKey={4} className={active === 'expertise' ? 'active' : ''}>
            <Micons name="bullseye" />
            <span className="nav-item-label">Expertise</span>
          </NavLink>
          <NavLink to={this.linkIsActive('experience') === 0 ? '/signup/experience' : '#'} eventKey={5} className={active === 'experience' ? 'active' : ''}>
            <Micons name="road" />
            <span className="nav-item-label">Experience</span>
          </NavLink>
          <NavLink to={this.linkIsActive('availability') === 0 ? '/signup/availability' : '#'} eventKey={5} className={active === 'availability' ? 'active' : ''}>
            <Micons name="calendar" />
            <span className="nav-item-label">Availability</span>
          </NavLink>
          <NavLink to={this.linkIsActive('background') === 0 ? '/signup/background' : '#'} eventKey={6} className={active === 'background' ? 'active' : ''}>
            <Micons name="book" />
            <span className="nav-item-label">Background</span>
          </NavLink>
        </div>
        <div className="progressbar-container">
          <ProgressBar now={complete(active)} />
        </div>
      </div>
    )
  }
}

const connectComponent = R.pipe(
  setPropTypes({ 
    isAuthenticated: PropTypes.bool,
    session: PropTypes.object
  }),
  connect((state) => ({
    isAuthenticated: state.login.isAuthenticated,
    session: state.session,
    user: state.login.user,
  })),
  withRouter,
);

export default connectComponent(WizardNaviation);
