import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import {submitLogout} from './actions';
import './style.css';

class LogoutForm extends React.Component {
    constructor(props, context){
        super(props, context);
        this.state = { }
    }
    componentWillMount() {
        this.props.dispatch(submitLogout());
        document.cookie = 'token=null; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/ ; domain=localhost';//TODO: Get location domain
        this.props.dispatch({type:'USER_LOGOUT'});
        this.props.history.push('/'); //redirect to home page        
       
    }

    render(){
        //This page is more to ensure that the routing works. 
        //Logout could be done in a single function definition however i think this gives us some flexibility.      
        
        return (    
            <div className='container-fluid'>
              you are now being logged out...
            </div>
        )
    }
};


LogoutForm.contextTypes = {
    store: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
}
LogoutForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
}



export default connect((state) => ({
}))(LogoutForm);