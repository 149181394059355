import React from 'react'
import { Modal, Button, Form, FormControl } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faSquare, faCheckSquare, faPlusSquare, faMinusSquare } from '@fortawesome/free-regular-svg-icons'
import { updateUser } from '../actions.js'
import * as experienceMap from '../../signup-wizard/experience'
import './style.css'
import CityInput from '../../city-input'
import { set } from 'immutable'
import moment from 'moment'
import companySizes from '../../signup-wizard/company_size'
import S3FileUpload from 'react-s3'
import consultingList from '../../signup-wizard/consulting.js'

const S3config = {
    bucketName: 'gighr-files',
    dirName: '',
    region: 'us-east-1',
    accessKeyId: 'AKIAJR3IYKBOFNYSLYJQ',
    secretAccessKey: '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',
}

const dotName = (parent, name) => {
  const parts = name.split('.')
  for (let i = 0; i < parts.length - 1; i++)
    parent = parent[parts[i]] || (parent[parts[i]] = {})
  return {
    get() {
      return parent[parts[parts.length - 1]]
    },
    set(value) {
      parent[parts[parts.length - 1]] = value
    }
  }
}

const dateToMonth = dateString => {
  if (!dateString) return ''
  if (dateString.match(/\d\d\d\d-\d\d/)) return dateString
  const timestamp = Date.parse(dateString)
  if (isNaN(timestamp)) return ''
  const date = new Date(dateString)
  const monthNum = date.getMonth() + 1
  const month = `${monthNum < 10 ? '0' : ''}${monthNum}`
  
  const yearNum = date.getFullYear()
  const year = (yearNum < 1000 ? ['0000', '000', '00', '0'][yearNum.toString().length] : '') + yearNum
  return `${year}-${month}`
}

const defaultObjectList = (user, field) => (user[field.key] && user[field.key].length)
  ? user[field.key].map(obj => Object.assign({}, obj))
  : [Object.keys(field.fields).map(f => field.fields[f].key)
    .reduce((accum, curr) => ({ ...accum, [curr]: '' }))]

const fieldMakers = {

  typeahead: (user, field) => Object.assign({
    value: user[field.key],
    options: experienceMap[field.key] || []
  }, field),

  multiCheckboxes: (user, field) => Object.assign({
    value: user[field.key] || []
  }, field),

  checkboxes: (user, field) => Object.assign({
    value: user[field.key]
  }, field),

  smallToggle: (user, field) => Object.assign({
    inline: true,
    value: user[field.key]
  }, field),

  toggle: (user, field) => Object.assign({
    options: ['Yes', 'No'],
    value: user[field.key]
  }, field),

  objects: (user, field) => Object.assign({
    value: defaultObjectList(user, field)
  }, field),

  text: (user, field) => Object.assign({
    value: user[field.key]
  }, field),

  month: (user, field) => Object.assign({
    value: moment(user[field.key]).format('YYYY-MM')
  }, field),

  city: (user, field) => Object.assign({
    value: dotName(user, field.key).get() || ''
  }, field),

  image: (user, field) => field

}

class UpdateModal extends React.Component {
  constructor(props, context) {
    super(props, context)
    const { user } = props
    this.state = { update: {}, loading: false }
    this.updateMap = {
      'Profile Info': {
        'First Name': {
          type: 'text',
          label: 'First Name',
          key: 'firstName'
        },
        'Last Name': {
          type: 'text',
          label: 'Last Name',
          key: 'lastName'
        },
        'Profile Picture': {
          type: 'image',
          label: 'Profile Picture',
          key: 'profile.picture'
        },
        'Summary': {
          type: 'text',
          label: 'Summary Headline',
          key: 'blurb'
        },
        'Location': {
          type: 'city',
          label: 'Location',
          key: 'profile.location'
        },
        'Phone Number': {
          type: 'text',
          label: 'Phone Number',
          key: 'phone_number'
        }
      },
      'Status': {
        'Available Full Time': {
          type: 'toggle',
          label: 'Are you available full time?',
          key: 'full_time'
        },
        'Available Part Time': {
          type: 'toggle',
          label: 'Are you available part time?',
          hide: user => user.full_time,
          key: 'part_time'
        },
        'Availability to Travel': {
          type: 'checkboxes',
          label: 'Please tell us about your ability to travel.',
          options: ['No travel', 'Up to 20%', 'Up to 50%', '80% travel'],
          key: 'travel'
        },
        'Ability to Work on Site': {
          type: 'checkboxes',
          label: 'If you are staffed locally, are you able to be on site?',
          options: ['Yes', 'No', 'Sometimes'],
          key: 'on_site'
        }
      },
      'Expertise': {
        'Primary Competency': {
          type: 'typeahead',
          key: 'primary_experience',
          label: 'We realize you may have broad experiences, but if you had to choose just one area that is your "sweetest" spot, what would it be?',
          options: experienceMap.primary_experience,
        },
        'Additional Competencies': {
          type: 'typeahead',
          key: 'secondary_experience',
          label: 'Within HR Operations & Service Delivery, what areas would you feel most comfortable independently delivering a project.',
          multiple: true,
          options: (!user.primary_experience || !user.primary_experience.length)
            ? [] : Object.keys(experienceMap.secondary_experience)
              .filter(sec => experienceMap.secondary_experience[sec].some(prim => user.primary_experience.includes(prim))),
        },
        'Industries': {
          type: 'typeahead',
          key: 'industry_experience',
          label: 'Can you tell us about your industry experience?',
          multiple: true,
        },
        'Functions': {
          type: 'typeahead',
          key: 'functional_experience',
          label: 'Can you tell us about the business functions you have supported?',
          multiple: true,
        },
        'Business Contexts': {
          type: 'typeahead',
          key: 'contextual_experience',
          label: 'Do you have significant experience in any of the following business contexts or environments?',
          multiple: true,
        },
        'Technologies': {
          type: 'typeahead',
          key: 'technology_experience',
          label: 'Are there any technologies that you would be considered an expert in? Please exclude technologies if you are only a user.',
          multiple: true,
        }
      },
      'Experience Summary': {
        'Consulting': [
          {
            type: 'toggle',
            label: 'Are you currently working as an Independent Consultant (IC)?',
            key: 'current_ic'
          },
          {
            type: 'checkboxes',
            label: 'How long have you been working as an IC?',
            options: ['< 2 years', '2-5 years', '> 5 years'],
            key: 'how_long_ic',
            hide: user => !user.current_ic
          },
          {
            type: 'toggle',
            label: 'Have you worked for consulting firms doing billable work?',
            key: 'billable_work'
          },
          {
            type: 'checkboxes',
            label: 'For how many years were you a billable consultant?',
            options: ['< 2 years', '2-5 years', '5-10 years', '10-15 years', '> 15 years'],
            key: 'how_long_bc',
            hide: user => !user.billable_work
          },
          {
            type: 'typeahead',
            key: 'consulting',
            label: 'Which consulting firms?',
            options: consultingList,
            multiple: true,
            hide: user => !user.billable_work
          },
        ],
        'Corporate': [
          {
            type: 'toggle',
            label: 'Do you have corporate experience?',
            key: 'corporate_experience'
          },
          {
            type: 'multiCheckboxes',
            label: 'What size companies have you worked for?',
            options: companySizes,
            key: 'company_size',
            hide: user => !user.corporate_experience
          },
          {
            type: 'toggle',
            label: 'Have you been part of an internal consulting function?',
            key: 'internal_consulting'
          },
          {
            type: 'toggle',
            label: 'Have you participated in an HR Leadership Development Program?',
            key: 'leadership_dev_prog'
          },
        ]
      },
      'Education Summary': {
        'Education': {
          type: 'objects',
          key: 'education',
          fields: {
            'Institution': {
              type: 'text',
              label: 'Institution',
              key: 'school'
            },
            'Degree': {
              type: 'text',
              label: 'Degree',
              key: 'degree'
            },
            'Major': {
              type: 'text',
              label: 'Major',
              key: 'subject'
            },
            'Graduation Year': {
              type: 'text',
              label: 'Graduation Year',
              key: 'graduationYear'
            }
          }
        }
      },
      'Project Experience': {
        'Projects': {
          type: 'objects',
          key: 'projects',
          fields: {
            'Title': {
              type: 'text',
              label: 'Project',
              key: 'title'
            },
            'Company Type': {
              type: 'text',
              label: 'Company Type',
              key: 'companyType'
            },
            'Summary': {
              type: 'text',
              label: 'Summary',
              key: 'blurb'
            },
          }
        }
      },
      'Work History': {
        'Work History': {
          type: 'objects',
          key: 'work_history',
          fields: {
            'Title': {
              type: 'text',
              label: 'Title',
              key: 'title'
            },
            'Company': {
              type: 'text',
              label: 'Company',
              key: 'company'
            },
            'Start Date': {
              type: 'month',
              label: 'Start Date',
              key: 'startDate'
            },
            'End Date': {
              type: 'month',
              label: 'End Date',
              key: 'endDate',
              hide: work_history => !!work_history.toPresent
            },
            'To Present': {
              type: 'smallToggle',
              label: 'To Present',
              key: 'toPresent'
            },
          }
        }
      }
    }
  }

  update(field, value) {
    if (field.type === 'typeahead' && field.multiple) {
      value = value.map(item => (typeof item === 'string' ? item : item.id))
    }
    const update = { ...this.props.user, ...this.state.update }
    dotName(update, field.key).set(value)
    this.setState({ update })
  }

  pushUpdate(field, value) {
    this.setState({ update: Object.assign({}, this.state.update, {
      [field.key]: [ ...(this.state.update[field.key] || this.props.user[field.key] || []), value ]
    }) })
  }

  save() {
    const email = this.props.user.email;
    ['education', 'projects', 'work_history'].forEach(key => {
      if (this.state.update[key] && this.state.update[key].length === 1) {
        const item = this.state.update[key][0]
        if (Object.keys(item).every(prop => prop === '_id' || (!item[prop] || item[prop].length === 0)))
          this.state.update[key] = []
      }
    })
    this.props.dispatch(updateUser(email, this.state.update, this.handleClose.bind(this)))
  }

  handleClose = () => {
    this.setState({ update: {}, loading: false })
    this.props.onClose()
  }

  handleShow = () => {

  }

  render() {
    const { category, show } = this.props
    const { updateMap, handleClose, handleShow } = this
    const { update } = this.state
    const user = Object.assign({}, this.props.user, update)

    const FieldInput = ({ field, updateField }) => {

      updateField = updateField || (value => {
        this.update(field, value)
      })

      return <React.Fragment>
        {field.type === 'typeahead' && (
          <Typeahead
            multiple={!!field.multiple}
            labelKey='label'
            options={field.options.map(_=>({id:_,label:_}))}
            onChange={selected => updateField(selected.map(_=>_.id))}
            selected={field.value.map(_=>({id:_,label:_}))}
            placeholder='Select all that apply'
            disabled={false} />
        )}
        {field.type === 'checkboxes' && (
          <div className='checkbox-container'>
            {field.options.map((option, index) => (
              <div key={index}
                className={`field-checkbox ${option === field.value ? 'checkbox-active' : ''}`}
                onClick={() => updateField(option)} >
                <div className='checkbox-icon'>
                  <FA icon={option === field.value ? faCheckSquare : faSquare} />
                </div>
                <div className='checkbox-label'><span>{option}</span></div>
              </div>
            ))}
          </div>
        )}
        {field.type === 'multiCheckboxes' && (
          <div className='checkbox-container'>
            {field.options.map((option, index) => (
              <div key={index}
                className={`field-checkbox ${field.value.includes(option) ? 'checkbox-active' : ''}`}
                onClick={() => {
                  const val = field.value

                  if (!val.includes(option)) {
                    updateField([...val, option])
                  } else {
                    const newVal = val.slice(0)
                    newVal.splice(newVal.indexOf(option), 1)
                    updateField(newVal)
                  }
                }} >
                <div className='checkbox-icon'>
                  <FA icon={option === field.value ? faCheckSquare : faSquare} />
                </div>
                <div className='checkbox-label'><span>{option}</span></div>
              </div>
            ))}
          </div>
        )}
        {field.type === 'smallToggle' && (
          <div className='small-toggle' onClick={() => updateField(!field.value)}>
            <FA icon={field.value ? faCheckSquare : faSquare} />
          </div>
        )}
        {field.type === 'toggle' && (
          <div className='checkbox-container toggle'>
            {field.options.map((option, index) => (
              <div key={index}
                className={`field-checkbox ${((option === 'Yes') === !!field.value) ? 'checkbox-active' : ''}`}
                onClick={() => updateField(option === 'Yes')} >
                <div className='checkbox-icon'>
                  <FA icon={option === field.value ? faCheckSquare : faSquare} />
                </div>
                <div className='checkbox-label'><span>{option}</span></div>
              </div>
            ))}
          </div>
        )}
        {field.type === 'objects' && (
          <React.Fragment>
            {field.value.map((obj, index) => (
              <React.Fragment key={`${field.key}-${index}`}>
                {Object.keys(field.fields).map(key => {
                  const childSource = field.fields[key]
                  const child = fieldMakers[childSource.type](obj, childSource)
                  if (child.hide && child.hide(obj)) return
                  return ( <React.Fragment key={`${key}`}>
                    <div className={`field-label ${child.inline ? 'field-inline' : ''}`}>{field.fields[key].label}</div>
                     {FieldInput({ field: child, updateField: val => {
                        const clone = field.value.slice(0)
                        clone[index][child.key] = val
                        this.setState({update: Object.assign({}, this.state.update, {
                          [field.key]: clone
                        })})
                      } })}
                  </React.Fragment> )
                })}
                <div className='remove-button'>
                  <Button
                    className='secondary'
                    onClick={() => {
                      if (field.value.length === 1) updateField([
                          Object.keys(field.fields).reduce((project, key) => ({
                            ...project,
                            [field.fields[key].key]: ''
                          }), {})
                        ])
                      else updateField(field.value.filter((o, i) => i !== index))
                    }} >
                    <FA icon={faMinusSquare} />
                    Remove
                  </Button>
                </div>
                <hr />
              </React.Fragment>
            ))}
            <div className='add-button'>
              <Button
                className='secondary'
                onClick={() => {
                  updateField([
                    ...(defaultObjectList(user, field)),
                    Object.keys(field.fields).reduce((project, key) => ({
                      ...project,
                      [field.fields[key].key]: ''
                    }), {})
                  ])
                }} >
                <FA icon={faPlusSquare} />
                Add
              </Button>
            </div>
          </React.Fragment>
        )}
        {field.type === 'text' && (
          <FormControl type='text'
            value={field.value}
            onChange={e => { updateField(e.target.value) }} />
        )}
        {field.type === 'month' && (
          <FormControl type='month'
            value={dateToMonth(field.value)}
            onChange={e => { updateField(moment(e.target.value)) }} />
        )}
        {field.type === 'city' && (
          <CityInput
            value={field.value}
            onChange={value => { updateField(value) }} />
        )}
        {field.type === 'image' && (
          <input
            type='file'
            onChange={e => {
              this.setState({ loading: true })
              S3config['dirName'] = user._id
              S3FileUpload.uploadFile(e.target.files[0], S3config)
                  .then(data => {
                    updateField(data.location)
                    this.setState({ loading: false })
                  })
                  .catch(err => {
                    console.error("Failed to upload to S3", err)
                  })
            }} />
        )}
      </React.Fragment>
    }

    return ( <Modal show={show} onHide={this.handleClose} >
      {show && ( <Modal.Body>
        <div className='title'>{category}:</div>
        <Form>
          {Object.keys(updateMap[category]).map((fieldName, index) => {
            const fieldSource = updateMap[category][fieldName]
            let field
            if (!Array.isArray(fieldSource)) {
              field = fieldMakers[fieldSource.type](user, fieldSource)
              if (field.hide && field.hide(user)) return
            }
            return <React.Fragment key={fieldName}>
              {Array.isArray(fieldSource) ? fieldSource.map(field => {
                field = fieldMakers[field.type](user, field)
                if (field.hide && field.hide(user)) return
                return ( <div className={`field-container ${field.inline && 'field-inline'}`} key={field.key}>
                  <div className='field-label'>{field.label}</div>
                  {FieldInput({ field })}
                </div> )
              }) : (
                <div className='field-container'>
                  <div className='field-label'>{field.label}</div>
                  {FieldInput({ field })}
                </div>
              )}
            </React.Fragment>
          })}
        </Form>
        <div className='actions'>
          <Button onClick={this.handleClose.bind(this)} bsStyle="inverted">Close</Button>
          <Button disabled={this.state.loading} onClick={this.save.bind(this)} bsStyle="primary">
            {this.state.loading ? 'Loading...' : 'Save'}
          </Button>
        </div>
      </Modal.Body> )}
    </Modal> )
  }

}

export default UpdateModal
