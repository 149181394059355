import React from 'react'
import './style.css'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'

import * as icons from '@fortawesome/free-solid-svg-icons'

const Attribute = ({title, children}) => (
    <div className='attribute'>
        <div className='attr-title highlight'>{title}</div>
        <div className='attr-body'>
            {children}
        </div>
    </div>
)

const AttributeRow = ({row: {subtitle, icon, text, label, location, bullet, indent}}) => (
    <div className={`attr-row ${subtitle ? 'subtitle' : ''}${location ? ' location' : ''}${bullet ? ' bullet' : ''}${indent ? ' indent' : ''}`} >
        {icon && <FA className='attr-row-icon' icon={icons[icon]} />}
        {label ? `${label}: ` : ''}
        {label ? <span>{text}</span> : text}
    </div>
)

const map = (arr, func) => {
    let res = []
    for (let i = 0; i < arr.length; i++)
        res.push(func(arr[i], i))
    return res
}

export default ({ title, data, oneColumn, edit }) => (
    <div className='category'>
        <div className='title'>{title}</div>
        {edit && (
            <div className='edit highlight' onClick={edit}>
                <FA icon={icons.faEdit} /> Edit
            </div>
        )}
        <div className={`${oneColumn ? '' : 'two-column'} attributes`}>
            {Array.isArray(data) ? data.map((pair, index) => (
                <Attribute key={index} title={Object.keys(pair)[0]}>
                    {map(pair[Object.keys(pair)[0]], (row, index) => <AttributeRow key={index} row={row} /> )}
                </Attribute>
            )) : Object.keys(data).map(attr => (
                <Attribute key={attr} title={attr}>
                    {map(data[attr], (row, index) => <AttributeRow key={index} row={row} /> )}
                </Attribute>
            ))}
        </div>
    </div>
)
