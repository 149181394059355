
import consulting from '../signup-wizard/consulting'
import { primary_experience, secondary_experience, technology_experience, industry_experience, functional_experience, contextual_experience } from '../signup-wizard/experience'

export default {
    current_ic: [
        'Yes',
        'No',
        'Previously'
    ],
    considering_ic: [
        'In the next 6 months',
        'Not in the next 6 months',
        'Interested in FT',
        'Not interested in FT'
    ],
    how_long_ic: [
        'Less than 2 years',
        '2-5 years',
        'More than 5 years'
    ],
    consulting,
    how_long_bc: [
        'Less than 2 years',
        '2-5 years',
        '5-10 years',
        '10-15 years',
        'More than 15 years'
    ],
    differentiators_DISABLED: [
        'Competitive Athlete',
        'Founded Startup',
        'Graduated Early',
        'Lean/Six Sigma',
        'Military',
        'Music Lover',
        'Pet Enthusiast',
        'Student Leadership Position (Government, Club, Sports, Greek Life)',
        'Worked/paid way through school',
        'World Traveler'
    ],
    primary_experience,
    secondary_experience,
    industry_experience,
    technology_experience,
    functional_experience,
    contextual_experience,
    // travel: {
    //     "No travel": 'None',
    //     "Up to 20%": 'Light (1-2 night/wk or 1 wk/mo)',
    //     "Up to 50%": 'Moderate (2-3 night/wk or 2 wk/mo)',
    //     "80% travel": 'Road Warrior (4 night/wk, every wk)'
    // },
    // on_site: {
    //     "Yes": 'Available',
    //     "Sometimes": 'Sometimes/As Needed',
    //     "No": 'All Remote'
    // },
    travel: [
        'None',
        'Light (1-2 night/wk or 1 wk/mo)',
        'Moderate (2-3 night/wk or 2 wk/mo)',
        'Road Warrior (4 night/wk, every wk)'
    ],
    on_site: [
        'Available',
        'Sometimes/As Needed',
        'All Remote'
    ],
    availability: [
        'Full Time',
        'Part Time',
        'Upcoming (within 6 weeks)',
        'Not Currently'
    ],
    degree: [
        'MBA',
        'MA',
        'MS',
        'PHD',
        'JD',
        'MD'
    ]
}