
const _ = require('lodash');
const initialState = {
    pending: false,
    success: false,
    error: false,
    user: {
        firstName: '',
        lastName: '',
        email: '',
        business: '',
        password: '',
        passwordConfirm: '',
        consulting:[],
        primary_experience: [],
        current_availability: []
    }
};

const signupWizardReducer = (state = initialState, action) => {
    switch(action.type){

        case 'CREATE_USER_PENDING':
            return { ...state, pending: true, success: false, error: false }
        case 'CREATE_USER_SUCCESS':
            return { ...state, pending: false, success: true, error: false, user: action.value }
        case 'CREATE_USER_ERROR':
            return { ...state, pending: false, success: false, error: action.error }

        case 'LOGIN_USER_PENDING':
            return { ...state, pending: true, success: false, error: false }
        case 'LOGIN_USER_SUCCESS':
            return { ...state, pending: false, success: true, error: false, user: action.value }
        case 'LOGIN_USER_ERROR':
            return { ...state, pending: false, success: false, error: action.error }

        case 'LINKEDIN_LOGIN_PENDING':
            return { ...state, pending: true, success: false, error: false }
        case 'LINKEDIN_LOGIN_SUCCESS':
            return { ...state, pending: false, success: true, error: false, user: action.value }
        case 'LINKEDIN_LOGIN_ERROR':
            return { ...state, pending: false, success: false, error: action.error }

        case 'UPDATE_WIZARD_FIELD':
            return { ...state, pending: false, success: true, user: { ...state.user, [action.value.field]: action.value.value }}

        case 'TOGGLE_TAG':
            const arr = state.user[action.field] || []
            if (!arr.includes(action.value)) {
                return { ...state, pending: false, success: true, user: { ...state.user, [action.field]: [ ...arr, action.value ] }}
            } else {
                const newArr = arr.slice(0)
                newArr.splice(newArr.indexOf(action.value), 1)
                return { ...state, pending: false, success: true, user: { ...state.user,
                    [action.field]: newArr
                } }
            }

        case 'UPDATE_SELECTED':
            console.log("reducer selected:", action.selected)
            return { ...state, pending: false, success: true, user: { ...state.user, [action.field]: action.selected }}

        default:
            return state;

    }
}

export default signupWizardReducer;
