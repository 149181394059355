const rp = require('request-promise').defaults({ baseUrl: window.location.origin, json: true });

export const updateUser = (email, update, cb) => dispatch => {
  dispatch({ type: 'UPDATE_USER_PENDING' })
  rp('/api/users', {
      method: 'POST',
      body: { user: { email, ...update } }
    })
    .then(res => {
      if (res.success) {
        dispatch({ type: 'UPDATE_USER_SUCCESS', value: res.user })
        return cb()
      } else {
        dispatch({ type: 'UPDATE_USER_ERROR', error: res })
      }
    })
    .catch( err=> dispatch({ type: 'UPDATE_USER_ERROR', error: err.message }))
}

export const updateField = (user) => (dispatch) => {
  dispatch({ type: 'UPDATE_USER_SUCCESS', value: user })
}

export const authUser = (resolve, reject) => dispatch => {
  dispatch({ type: 'AUTH_USER_PENDING' })
  rp('/api/users/login', { method: 'POST', body: {} })
    .then(res => {
      if (res.success) {
        dispatch({ type: 'AUTH_USER_SUCCESS', value: res.user })
        return resolve()
      } else {
        dispatch({ type: 'AUTH_USER_ERROR', error: res })
        return reject()
      }
    })
    .catch( err=> {
      dispatch({ type: 'AUTH_USER_ERROR', error: err.message })
      return reject()
    })
}

export const fetchUser = (id, resolve, reject) => dispatch => {
  dispatch({ type: 'FETCH_USER_PENDING' })
  rp('/api/users/' + id, { method: 'GET' })
    .then(res => {
      if (res.success) {
        dispatch({ type: 'FETCH_USER_SUCCESS', value: res.user })
        return resolve(res.user)
      } else {
        dispatch({ type: 'FETCH_USER_ERROR', error: res.error })
        return reject(res.error)
      }
    })
    .catch(err => {
      dispatch({ type: 'FETCH_USER_ERROR', error: err.message })
      return reject(err)
    })
}

export const logoutUser = cb => dispatch => {
    dispatch({ type: 'LOGOUT_USER_PENDING' })
    rp(`/api/users/logout`, { method: 'GET' })
      .then(response => {
          if (!response.success) return dispatch({ type: 'LOGOUT_USER_ERROR', value: response })
          dispatch({ type: 'LOGOUT_USER_SUCCESS' })
          cb()
      })
      .catch(err => dispatch({ type: 'LOGOUT_USER_ERROR', value: err }))
}