import { resolve } from 'url';

const rp = require('request-promise').defaults({ baseUrl: window.location.origin, json: true });
// const rp = require('request-promise').defaults({ baseUrl: 'http://localhost:8080', json: true });
//const _ = require('lodash');


export const updateField = (field, value) => (dispatch) => {
    dispatch({ type: 'UPDATE_WIZARD_FIELD', value: { field, value }})
}

// Create new user
export const createUser = (user, redir, cb) => async (dispatch) => {
    dispatch({ type: 'CREATE_USER_PENDING' })
    await rp(`/api/users/register`, {
        method: 'POST',
        body: { user }
    })
    .then( response => {
        if (response.success) {
            dispatch({ type: 'CREATE_USER_SUCCESS', value: response.user })
            redir('/signup/welcome')
        } else {
            dispatch({ type: 'CREATE_USER_ERROR', error: response.error })
            if (cb) cb(response.error.message)
        }
    })
    .catch( err=> {
        dispatch({ type: 'CREATE_USER_ERROR', error: err.message })
        if (cb) cb('Could not create user.')
    })
}

// LinkedIn Create/Login
export const loginWithLinkedIn = ({code, redirect_uri}) => async (dispatch) => {
    dispatch({ type: 'LINKEDIN_LOGIN_PENDING' })
    await rp(`/api/linkedin/accesstoken`, {
        method: 'POST',
        body: {
            code,
            redirect_uri
        }
    })
    .then( response => {
        if (response.success) {
            dispatch({ type: 'LINKEDIN_LOGIN_SUCCESS', value: response.user })
        } else {
            console.log(response);
            dispatch({ type: 'LINKEDIN_LOGIN_ERROR', value: response.error })
        }
    })
    .catch (err => dispatch({ type: 'LINKEDIN_LOGIN_ERROR', error: err.message }))

}

// Regular login
export const loginUser = ({email, password, redir, cb}) => async (dispatch) => {
    dispatch({ type: 'LOGIN_USER_PENDING' })
    await rp(`/api/users/login`, {
        method: 'POST',
        body: {
            username: email,
            password: password
        }
    })
    .then(response => {
        if (!response.user) {
            dispatch({ type: 'LOGIN_USER_ERROR', value: response })
            if (cb) cb('Incorrect email or password.')
            return
        }
        dispatch({ type: 'LOGIN_USER_SUCCESS', value: { ...response.user, isAuthenticated: true }})

        if (response.user.intakeTab) redir(`/signup/${response.user.intakeTab}`)
        else redir('/profile')
    })
    .catch(err => {
        dispatch({ type: 'LOGIN_USER_ERROR', value: err.message })
        if (cb) cb(err.statusCode === 401 ? 'Incorrect email or password.' : 'Could not log in.')
    })
}

export const logoutUser = cb => dispatch => {
    dispatch({ type: 'LOGOUT_USER_PENDING' })
    rp(`/api/users/logout`, { method: 'GET' })
        .then(response => {
            if (!response.success) return dispatch({ type: 'LOGOUT_USER_ERROR', value: response })
            dispatch({ type: 'LOGOUT_USER_SUCCESS' })
            cb()
        })
        .catch(err => dispatch({ type: 'LOGOUT_USER_ERROR', value: err }))
}

// Update user
export const updateUser = (user, intakeTab, cb) => async (dispatch) => {
    
    if(user.emailAddress !== undefined && user.email === undefined)
        user.email = user.emailAddress

    dispatch({ type: 'UPDATE_USER_PENDING' })
    await rp(`/api/users`, {
        method: 'POST',
        body: (intakeTab === undefined) ? { user } : { user: { ...user, intakeTab } }
    })
    .then( res => {
        dispatch({ type: 'UPDATE_USER_SUCCESS', value: res.user })
        if (cb) cb()
    })
    .catch( err => dispatch({ type: 'UDPATE_USER_ERROR', error: err.message }))
}

//toggle tag field on/off
export const toggleTag = (field, value) => (dispatch) => {
    dispatch({ type: 'TOGGLE_TAG', field, value })
}

//update select/multiselct values
export const updateSelected = (field, selected) => (dispatch) => {
    //const values = []
    //_.map(selected, item => values.push(item.label))
    dispatch({ type: 'UPDATE_SELECTED', field, selected })
}


export const authUser = (resolve, reject) => dispatch => {
    dispatch({ type: 'AUTH_USER_PENDING' })
    rp('/api/users/login', { method: 'POST', body: {} })
      .then(res => {
        if (res.success) {
          dispatch({ type: 'AUTH_USER_SUCCESS', value: res.user })
          dispatch({ type: 'LOGIN_USER_SUCCESS', value: res.user})
          return resolve()
        } else {
          dispatch({ type: 'AUTH_USER_ERROR', error: res })
          return reject()
        }
      })
      .catch( err=> {
        dispatch({ type: 'AUTH_USER_ERROR', error: err.message })
        return reject()
      })
  }