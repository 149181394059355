const R = require('ramda');

const initialState = { 
    pending: false,
    sucess: false,
    error: null,
    email: '',
    formIsValid: false,
    tokenValid: false,
    resetSuccess: false,
};

const areEqual = R.converge(R.equals, [R.prop('password'), R.prop('passwordConfirm')]);
const buildPropNotNil = (propKey) => R.pipe(R.prop(propKey), R.complement(R.isNil));
const areNotNil = R.converge(R.and, [buildPropNotNil('password'), buildPropNotNil('passwordConfirm')]);
const isValid = R.allPass([areEqual, areNotNil]);



const setPasswordReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_RESET_PASSWORD': {
            const { type, ...rest } = action;
            const formIsValid = isValid({ ...state, ...rest });
            return { ...state, ...rest, formIsValid };
        }
        

        case 'SUBMIT_RESET_PASSWORD_PENDING':
            return { ...state, pending: true, success: false, error: null, resetSucess: false }
        case 'SUBMIT_RESET_PASSWORD_SUCCESS':
            return { ...state, success: true, pending: false, ...action.value, resetSuccess: true }
        case 'SUBMIT_RESET_PASSWORD_ERROR':
            return { 
                ...state, 
                success: false, 
                pending: false, 
                resetSuccess: false,
                error: action.error.message
            }

        case 'SUBMIT_VERIFY_RESET_TOKEN_PENDING':
            return { ...state, pending: true, success: false, error: null, tokenValid: false }
        case 'SUBMIT_VERIFY_RESET_TOKEN_SUCCESS':
            return { ...state, success: true, pending: false, ...action.value, tokenValid: true }
        case 'SUBMIT_VERIFY_RESET_TOKEN_ERROR':
            return { 
                ...state, 
                success: false, 
                pending: false, 
                tokenValid: false,
                error: action.error.message,
            }


        default:
            return state;
    }
}


export default setPasswordReducer;