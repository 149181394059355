import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import * as R from 'ramda'
import _ from 'lodash'
import './style.css'
import moment, { isMoment } from 'moment'

import Header from './header'
import UpdateModal from './update-modal'
import FilesModal from './files-modal'
import Category from './category'

import { authUser, logoutUser, fetchUser } from './actions'

const stringToRow = text => ({ text })
const stringsToRows = texts => texts.map(stringToRow)
const stringsToCommaRow = texts => ([{ text: texts.join(', ') }])

const mapToRows = map => Object.keys(map).map(label => ({ label, text: map[label] }))

const condArray = arr => arr.filter(ele => !!ele);

const bulleted = rows => rows.map(row => Object.assign({bullet: true}, row))

const dateRangeString = ({ startDate, endDate, toPresent }) => {
    const start = moment(startDate)
    const end = endDate !== null ? moment(endDate) : moment()
    if (!isMoment(start) || !isMoment(end)) return 'Invalid employment duration.'
    const yearDiff = end.diff(start, 'years')
    return `${start.format('MMM YYYY')} - ${toPresent ? 'Present' : end.format('MMM YYYY')}; ${ yearDiff === 0 ? ' < 1 year' : yearDiff + (yearDiff === 1 ? ' year' : ' years') }`
}

class ProfileManager extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = { editing: null, specifiedUser: null }
        if (props.match.params._id) {
            props.dispatch(fetchUser(props.match.params._id, user => {
                this.setState({ specifiedUser: user })
            }, () => {
                props.history.push('/profile')
            }))
        } else if (!props.user._id) {
            this.state.fetching = true
            props.dispatch(authUser(() => {
                if (this.props.user.approved) {
                    this.data = this.makeData(this.props.user)
                    this.setState({ fetching: false })
                } else {
                    props.history.push(`/signup/${this.props.user.intakeTab || 'welcome'}`)
                }
            }, () => {
                props.history.push('/signup/login?account')
            }))
        } else {
            if (this.props.user.approved) {
                this.data = this.makeData(props.user)
            } else {
                props.history.push(`/signup/${this.props.user.intakeTab || 'welcome'}`)
            }
        }
    }

    makeData = user => ({
        categories: {
            'Expertise': {
                'Primary Competency': stringsToCommaRow(user.primary_experience),
                'Business Contexts': bulleted(stringsToRows(user.contextual_experience)),
                'Additional Competencies': bulleted(stringsToRows(user.secondary_experience)),
                'Technologies': bulleted(stringsToRows(user.technology_experience)),
                'Industries': bulleted(stringsToRows(user.industry_experience)), // was obj
                'Functions': bulleted(stringsToRows(user.functional_experience)) // was obj
            },
            'Experience Summary': {
                'Consulting': bulleted(condArray([
                    { label: 'Internal Consulting', text: user.current_ic ? user.how_long_ic : 'No' },
                    user.billable_work && { label: 'Billable Consulting', text: user.how_long_bc },
                ])),
                'Corporate': bulleted(condArray([
                    { text: user.corporate_experience ? `Company Size` : 'No corporate experience' },
                    ...((user.company_size || []).map(size => ({ indent: true, text: size }))),
                    user.internal_consulting && { text: 'Internal Consulting' },
                    user.leadership_dev_prog && { text: 'HR Leadership Development Program' },
                ])),
            },
            'Education Summary': (user.education || []).map(source => ({
                [source.subject]: [{
                    icon: 'faSchool',
                    subtitle: true,
                    text: `${source.school}, ${source.degree}, ${source.graduationYear}`
                }]
            })),
            'Project Experience': (user.projects || []).map(source => ({
                [source.title]: [
                    { subtitle: true, text: source.companyType },
                    { text: source.blurb }
                ]
            })),
            'Work History': (user.work_history || []).map(source => ({
                [source.title]: [
                    { subtitle: true, icon: 'faSchool', text: source.company },
                    { subtitle: true, text: dateRangeString(source) },
                ]
            }))
        }
    })

    handleClose = () => {
        this.setState({ editing: null })
    }

    render() {
        if (this.state.fetching || !(this.state.specifiedUser || this.props.user._id)) return ''

        const user = this.state.specifiedUser || this.props.user
        this.data = this.makeData(user)
        const { categories } = this.data
        const { editing, editingFiles } = this.state

        const makeEdit = name => this.state.specifiedUser ? null : () => { this.setState({ editing: name }) }
        const editFiles = () => this.state.specifiedUser ? null : () => { this.setState({editingFiles: true})}
        const getFile = fileType => {
            const file = _.find(user.files, { fileType }, {})
            if(file === undefined)
                return <span>None</span>

            return <a href={file.fileUrl} target="_blank">{file.fileName}</a>
        }

        return (
            <div className='profile-manager'>
                <UpdateModal
                    user={user}
                    category={editing}
                    onClose={this.handleClose}
                    dispatch={this.props.dispatch}
                    show={!!editing} />
                <FilesModal
                    user={user}
                    onClose={() => this.setState({ editingFiles: null })}
                    dispatch={this.props.dispatch}
                    show={!!editingFiles} />
                <Header
                    edit={makeEdit('Profile Info')}
                    user={user}
                    logout={() => {
                        this.props.dispatch(logoutUser(() => {
                            this.props.history.push('/signup/login?account')
                        }))
                    }} />
                <div className='info-columns'>
                    <div className='status-column'>
                        <Category
                            title='Status'
                            oneColumn={true}
                            edit={makeEdit('Status')}
                            data={{
                                'Current Availability': [{ text: user.full_time
                                        ? 'Available Full Time'
                                        : ( user.part_time
                                            ? 'Available Part Time'
                                            : 'Unavailable' ) }],
                                'Availability to Travel': [{ text: user.travel }],
                                'Ability to Work on Site': [{ text: user.on_site }],
                            }} />
                        <Category
                            title='Files'
                            oneColumn={true}
                            edit={editFiles()}
                            data={{
                                'Resume': [{ text: getFile('resume') }],
                                'Bio': [{text: getFile('bio')}],
                                'Project Summaries': [{ text: getFile('project_summaries') }]
                            }} />
                    </div>
                    <div className='main-column'>
                        {Object.keys(categories).map((category, i) => (
                            <Category key={i}
                                title={category}
                                edit={makeEdit(category)}
                                oneColumn={i > 1}
                                data={categories[category]} />
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

ProfileManager.contextTypes = {
    store: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
}

ProfileManager.propTypes = {
    dispatch: PropTypes.func.isRequired
}

const connectComponent = R.pipe(
    connect((state) => ({
        profile: state.profile_manager,
        user: state.user
    })),
    withRouter
);

export default connectComponent(ProfileManager);
