import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, Button, InputGroup } from 'react-bootstrap';

import { submitLogin } from './actions';
import './style.css';

class LoginForm extends React.Component {
    constructor(props, context){
        super(props, context);
        this.state = { username: undefined, password: undefined }
    }
    handleChange = (field) => (evt, value) => {
        this.setState({ [field]: value })
        this.props.dispatch({ type: 'SET_USERNAME_PASSWORD', [field]: evt.target.value });
    }
    handleSubmit = (evt) => {
        evt.preventDefault();
        this.props.dispatch({ type: 'SET_USERNAME_PASSWORD', username: this.props.username, password: this.props.password });
        this.props.dispatch(submitLogin(this.props.username, this.props.password));
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.isAuthenticated && !this.props.isAuthenticated) {
            this.setState({ redirectToReferer: true });
        }
    }

    render(){
        if (this.props.isAuthenticated){
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            return (
                <Redirect to={from} />
            );
        }
        return (
            <div className='container-fluid'>
                <form className='form-signin' onSubmit={this.handleSubmit}>
                    <h1 className='text-center text-uppercase form-signin-heading text-purple margin-top-zero'>GigHR</h1>
                    <div className="col-xs-10 col-xs-offset-1 login-inputs">
                        <FormGroup controlId="formBasicTextUsername">
                            <InputGroup>
                                <FormControl type="text" value={this.props.username} onChange={this.handleChange('username')} placeholder="Username" />
                                <InputGroup.Addon> <i className="fas fa-user-alt"></i> </InputGroup.Addon>
                                <FormControl.Feedback />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup controlId="formBasicTextPassword">
                            <InputGroup>
                                <FormControl type="password" value={this.props.password} onChange={this.handleChange('password')} placeholder="Password" />
                                <InputGroup.Addon> <i className="fas fa-lock"></i> </InputGroup.Addon>
                                <FormControl.Feedback />
                            </InputGroup>
                        </FormGroup>
                    </div>
                        
                    <FormGroup controlId="formBasicTextSubmit" className="text-center margin-bottom-large">
                        <Button type='submit' className="btn btn-purple margin-vert-zero" >Submit</Button>
                    </FormGroup>
                    
                    <FormGroup controlId="formBasicText" className="text-center margin-bottom-zero" validationState={'success'} >
                        <p className="text-center text-grey password-reset margin-bottom-zero">
                            Forgot your password? Click <Link 
                                to="/password-reset" 
                                className="text-purple text-uppercase"
                            >
                                HERE
                            </Link>
                        </p>
                    </FormGroup>
                </form>
                <span className="copyright">Copyright. GigHR</span>
            </div>
        )
    }
};


LoginForm.contextTypes = {
    store: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
}
LoginForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
}



export default connect((state) => ({
    username: state.login.username,
    password: state.login.password,
    isAuthenticated: state.login.isAuthenticated
}))(LoginForm);