import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav, NavItem, NavDropdown, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { setPropTypes } from 'recompose';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import _ from 'lodash';

import { logoutUser } from './actions';


const NavLink = (props) => <li><Link className={props.className} to={props.to}>{props.children}</Link></li>;
const NavLinkAnchor = (props) => <li><button type='button' {...props}>{props.children}</button></li>;


class Navigationbar extends Component {
  state = { showProfileModal: false };

  logout = () => {
    this.props.dispatch(logoutUser(() => {
        this.props.history.push('/signup/login?account')
    }))
  }

  render() {
      // if (!this.props.isAuthenticated){
      //   return <div></div>
      // }
    return(
      <React.Fragment>
        <Navbar collapseOnSelect className="main-nav">
          <Navbar.Brand href="#home">
            <img
              src="/img/gighr.png"
              width="100"
              alt="gighr logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="mr-auto">
                <li><a href='http://www.gighr.com/#section-why-gighr' className='nav-item'>Why Gighr?</a></li>
                <li><a href='http://www.gighr.com/#section-our-consultants' className='nav-item'>Our Consultants</a></li>
                <li><a href='http://www.gighr.com/#section-how-it-works' className='nav-item'>How it Works</a></li>
                <li><a href='http://www.gighr.com/#section-testimonials' className='nav-item'>Success Stories</a></li>
                <li><a href='http://www.gighr.com/#section-faq' className='nav-item'>FAQ</a></li>
              {this.props.user._id && this.props.user.approved && (
                <NavLink className="nav-item" to='/profile' href="#">Profile</NavLink>
              )}
              {this.props.user._id && this.props.user.role === 'admin' && (
                <NavLink className="nav-item" to='/admin-home' href="#">Admin Home</NavLink>
              )}
              {this.props.user._id && (
                <li className="logout"><a href='#' className='nav-item' onClick={this.logout}>Logout</a></li>
              )}
            </Nav>


            {/* <Nav pullRight className="upload-step" md={2} style={{display: 'flex', alignItems: 'flex-end'}}>
              <NavDropdown id="basic-nav-dropdown" title={`Welcome back, ${this.props.user.firstName} ${this.props.user.lastName}`} >
                <NavLinkAnchor onClick={this.setShowProfileModal(true)}>Profile</NavLinkAnchor>
                <NavLink to="/logout">Logout</NavLink>
              </NavDropdown>
              <NavItem href="#" className={'avi'}>
                <Image src={this.props.user.profileImage || '/images/default.png'} width="40" height="40" circle />
              </NavItem>
            </Nav> */}
          </Navbar.Collapse>
        </Navbar>

        {/* <ProfileModal 
          show={this.state.showProfileModal}
          handleClose={this.setShowProfileModal(false)}
          submissions={_.omit(this.props.submissions, ['satisfaction'])}
          user={this.props.user}
        /> */}
      </React.Fragment>
    )
  }
}

const connectComponent = R.pipe(
  setPropTypes({ 
    isAuthenticated: PropTypes.bool,
    session: PropTypes.object
  }),
  connect((state) => ({
    user: state.user,
  })),
  withRouter,
);

export default connectComponent(Navigationbar);
