import React from 'react'
import './style.css'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import NumberFormat from 'react-number-format'
import { faMapMarkerAlt, faEnvelope, faPhone, faEdit } from '@fortawesome/free-solid-svg-icons'

const formatTel = text => {
    text = text.replace(/[^\d+]+/g, '').replace(/^00/, '+')
    if (text.match(/^1/)) text = '+' + text
    if (!text.match(/^\+/)) text = '+1' + text
    return text.slice(1)
}

const Attribute = ({icon, text, tel}) => (
    <div className='attribute'>
        <div className='attr-icon highlight'><FA icon={icon} /></div>
        <div className='attr-text'>
            {!tel ? text : (!text ? ''
                : <NumberFormat displayType='text' format='+# (###) ###-####' value={formatTel(text)} /> )}
        </div>
    </div>
)

const ProfilePicture = ({src}) => (
    <div className='picture' style={{backgroundImage: `url('${src}')`}} />
)

export default ({edit, logout, user: {zip_code, firstName, lastName, blurb, email, phone_number, profile}}) => (
    <div className='header row'>
        <div className='picture-column col-md-5'>
            <ProfilePicture src={(profile && profile.picture) || '/img/default-avatar.png'} />
        </div>
        <div className='info-column col-md-7'>
            {edit && <div className='edit' onClick={edit}><FA icon={faEdit} /> Edit</div>}
            <div className='title highlight'>{firstName} {lastName}</div>
            <div className='blurb'>{blurb}</div>
            <div className='attributes'>
                <Attribute icon={faMapMarkerAlt} text={(profile && profile.location) || zip_code || 'No Location Given'} />
                <Attribute icon={faEnvelope} text={email} />
                <Attribute icon={faPhone} text={phone_number} tel={true} />
            </div>
        </div>
    </div>
)
