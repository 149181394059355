
const initialState = { 
    pending: false,
    success: false,
    error: null,
    password: '',
    passwordConfirm: '',
    formIsValid: false  
};




const requestPasswordResetReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_RESET_EMAIL': {
            const { type, ...rest } = action;
            return { ...state, ...rest };
        }


        case 'SUBMIT_RESET_PASSWORD_EMAIL_PENDING':
            return { ...state, success: false, pending: true, error: null }
        case 'SUBMIT_RESET_PASSWORD_EMAIL_SUCCESS':
            return { ...state, success: true, pending: false, ...action.value }
        case 'SUBMIT_RESET_PASSWORD_EMAIL_ERROR':
            return { 
                ...state, 
                success: false, 
                pending: false, 
                error: action.error.message
            }
    
        default:
            return state;
    }
}


export default requestPasswordResetReducer;