const initialState = { 
  pending: false,
  success: false,
  error: null,
  users: []
};

const adminReducer = (state = initialState, action) => {
  switch(action.type){

    case 'FETCH_USERS_PENDING':
        return { ...state, pending: true, success: false }
    case 'FETCH_USERS_SUCCESS':
        return { ...state, pending: false, success: true, users: action.value}
    case 'FETCH_USERS_ERROR':
        return { ...state, pending: false, success: false, error: true }

    default:
          return state;
  }
}


export default adminReducer;