const consulting = [
    'Accenture',
    'ACS',
    'AlixPartners',
    'Andersen Consulting (Legacy)',
    'Aon',
    'Appirio by Wipro',
    'Arthur J. Gallagher',
    'AT Kearney',
    'Bain',
    'BearingPoint',
    'Bersin by Deloitte',
    'Booz Allen Hamilton',
    'Boston Consulting Group',
    'Buck',
    'Capgemini',
    'Catalyst',
    'CEB',
    'Compensation Advisory Partners',
    'Compensia',
    'Conduent',
    'Convergys',
    'DDI',
    'Deloitte',
    'Delta (Legacy)',
    'EY',
    'Fred Cook',
    'Gallup',
    'Grant Thornton',
    'Great Place to Work',
    'Hackett',
    'Hay Group (Legacy)',
    'Heidrick & Struggles',
    'Hewitt (Legacy)',
    'Hitachi',
    'Huron',
    'IBM',
    'Katzenbach (Legacy)',
    'Ketchum',
    'Korn Ferry',
    'KPMG',
    'Lee Hecht Harrison',
    'McKinsey',
    'McLagan by Aon',
    'Mercer',
    'Meridian',
    'Monitor by Deloitte',
    'NGA',
    'North Highland',
    'Oliver Wyman',
    'Pay Governance',
    'PDI',
    'Pearl Meyer',
    'PwC',
    'Radford by Aon',
    'RHR',
    'Right Management',
    'Russell Reynolds',
    'Segal',
    'Semler Brossy',
    'Sheffield Barry',
    'Sibson',
    'Slalom',
    'Spencer Stuart',
    'Strategy& by PwC',
    'Stromberg',
    'SullivanCotter',
    'Veritas',
    'WillisTowersWatson',
    'YSC',
    'Other',
    'None',
]

export default consulting
