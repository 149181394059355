export const primary_experience = [
    "HR Business Partner/Generalist",
    "HR Operations & Service Delivery",
    "HR Strategy & Transformation",
    "HR Technology",
    "Labor and Employee Relations",
    "Leadership, Learning and Development",
    "Organization Development/Effectiveness & Change Management",
    "Talent Acquisition",
    "Talent Management",
    "Total Rewards/Compensation & Benefits",
];

export const secondary_experience = {
    "Analytics/Reporting": ["HR Operations & Service Delivery", "HR Strategy & Transformation"],
    "Assessment Tools": ["Leadership, Learning and Development"],
    "Benefits Actuary": ["Total Rewards/Compensation & Benefits"],
    "Benefits Administration": ["Total Rewards/Compensation & Benefits"],
    "Benefits Design": ["Total Rewards/Compensation & Benefits"],
    "Benefits Health & Welfare": ["Total Rewards/Compensation & Benefits"],
    "Benefits Pension/Retirement": ["Total Rewards/Compensation & Benefits"],
    "Business Development/Account Management": ["All"],
    "Change Management - General": ["Organization Development/Effectiveness & Change Management"],
    "Change Management - Tech Adoption": ["Organization Development/Effectiveness & Change Management"],
    "Communications - External/Agency": ["Organization Development/Effectiveness & Change Management"],
    "Communications - Internal": ["Organization Development/Effectiveness & Change Management"],
    "Compensation - Executive": ["Total Rewards/Compensation & Benefits"],
    "Compensation - General": ["Total Rewards/Compensation & Benefits"],
    "Compensation - Sales ": ["Total Rewards/Compensation & Benefits"],
    "Competency Modeling": ["Talent Management"],
    "Compliance": ["HR Business Partner/Generalist"],
    "Culture Assessment": ["Organization Development/Effectiveness & Change Management"],
    "Culture Transformation": ["Organization Development/Effectiveness & Change Management"],
    "Diversity And Inclusion": ["Talent Management"],
    "Employee Engagement ": ["Organization Development/Effectiveness & Change Management"],
    "Employee Experience": ["Organization Development/Effectiveness & Change Management", "Talent Management"],
    "Employee Relations": ["Labor & Employee Relations"],
    "Employer Brand": ["Organization Development/Effectiveness & Change Management", "Talent Management", "Talent Acquisition"],
    "Executive Coaching ": ["Leadership, Learning and Development"],
    "Facilitation": ["Leadership, Learning and Development"],
    "Global Mobility/Tax/Expats": ["HR Operations & Service Delivery", "Total Rewards/Compensation & Benefits"],
    "HR Business Partner/Generalist": ["HR Business Partner/Generalist"],
    "HR Offshoring/Outsourcing": ["HR Operations & Service Delivery"],
    "HR Process Redesign/Re-Engineering": ["HR Operations & Service Delivery", "HR Strategy & Transformation"],
    "HR Strategy": ["HR Strategy & Transformation"],
    "HR Transformation": ["HR Strategy & Transformation"],
    "Instructional or Curriculum Design ": ["Leadership, Learning and Development"],
    "Leadership Assessment": ["Leadership, Learning and Development"],
    "Learning Partner": ["Leadership, Learning and Development"],
    "Organization Design": ["Organization Development/Effectiveness & Change Management"],
    "Organization Effectiveness & Development": ["Organization Development/Effectiveness & Change Management"],
    "Payroll": ["HR Operations & Service Delivery"],
    "Performance Management": ["Talent Management"],
    "Program Development (Leadership/Learning) ": ["Leadership, Learning and Development"],
    "Program Management (Rotational/Mgmt Dev)": ["Talent Management"],
    "Program Management Office": ["All"],
    "Recruiting - Campus": ["Talent Acquisition"],
    "Recruiting - Enterprise Talent Acquisition Strategy ": ["Talent Acquisition"],
    "Recruiting - Executive Search ": ["Talent Acquisition"],
    "Recruiting - External (Agency/RPO)": ["Talent Acquisition"],
    "Recruiting - Internal/Experienced": ["Talent Acquisition"],
    "Recruiting - Vendor/RPO Mgmt": ["Talent Acquisition"],
    "Sales Force Effectiveness": ["Organization Development/Effectiveness & Change Management", "Talent Management", "Total Rewards/Compensation & Benefits"],
    "Shared Services Design and Management": ["HR Operations & Service Delivery"],
    "Succession Planning ": ["Talent Management"],
    "Talent Management Strategy": ["Talent Management"],
    "Vendor Selection": ["HR Operations & Service Delivery", "HR Technology", "Leadership, Learning and Development"],
    "Workforce Planning": ["Talent Management"],
}

export const technology_experience = [
    'ADP',
    'Cornerstone',
    'Oracle Fusion',
    'Oracle HCM Cloud',
    'Oracle On Premise',
    'PeopleSoft',
    'SAP',
    'SAP SuccessFactors',
    'Taleo',
    'Ultimate',
    'Workday',
    'Other Learning Management System',
    'Other Recruiting/ATS',
    'Other Talent/Performance Management',
    'Other HRIS',
]

export const industry_experience = [
    'Advertising & PR',
    'Aerospace & Defense',
    'Agriculture',
    'Automotive',
    'Building Products and Construction',
    'Consumer Products',
    'Education',
    'Financial Services - General, Commercial, Retail',
    'Financial Services - Insurance',
    'Financial Services - Investment Banking, Asset Management, Private Equity, Hedge Funds',
    'Government',
    'Health Plans/Payors',
    'Healthcare Providers',
    'Professional Services (Accounting, Consulting, Law)',
    'Life Sciences - Biotech',
    'Life Sciences - Med Device/Diagnostics',
    'Life Sciences - Pharma',
    'Manufacturing and Industrial Products',
    'Media and Entertainment',
    'Not for Profit',
    'Oil and Gas',
    'Power and Utilities',
    'Real Estate',
    'Retail/eCommerce',
    'Technology',
    'Telecom',
    'Tourism, Hospitality and Leisure',
    'Transportation and Logistics',
]

export const functional_experience = [
    'Corporate Functions',
    'Customer Service/Call Center',
    'Engineering/Product Development',
    'Finance',
    'Human Resources',
    'Information Technology',
    'Marketing',
    'Operations',
    'Research and Development',
    'Risk Management/Legal/Regulatory/Compliance',
    'Sales',
    'Strategy/Corporate Development',
    'Supply Chain (Procurement, Manufacturing/Plant, Distribution)',
]

export const contextual_experience = [
    'M&A',
    'Divestiture',
    'IPO',
    'Public to Private',
    'Business Transformation',
    'Global Expansion',
    'Hyper Growth',
    'Launching New Business Unit/Function',
    'Non-Tech Start Up',
    'Tech Start Up',
    'Early Career Outside of HR',
    'Business Role Outside of HR',
    'Business/Plant Closure',
    'Union/Labor/Works Councils',
    'VC Backed',
    'PE Backed',
    'Chief of Staff',
    'Head of HR/CHRO/CTO',
    'Board/Comp Committee Presentation',
    'Non-HR Leadership Development Program',
]