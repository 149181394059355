import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { defaultProps, mapProps } from 'recompose';
import { FormControl, Pager, Button, Grid, Col } from 'react-bootstrap';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters, Editors } from "react-data-grid-addons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchUsers, authUser } from './actions'
import './style.css';
import _ from 'lodash'
import { faAngleRight, faAngleLeft, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment'
import validate from './export-values'
const rp = require('request-promise').defaults({ baseUrl: window.location.origin, json: true });


const parseToDate = R.pipe(
    R.propOr('', 'value'),
    R.ifElse(
        R.anyPass([R.isNil, R.isEmpty]),
        R.always('-'),
        (val) => moment(val).format('DD-MMM-YYYY')
    )
)

const formatStringToDate = (date) => {
    return !_.isNil(date) ? moment(date).format("DD-MM-YYYY") : ""
}

function getValidFilterValues(rows, columnId) {
    return rows
      .map(r => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      });
  }

const selectors = Data.Selectors;


const {
    NumericFilter,
    AutoCompleteFilter,
    MultiSelectFilter,
    SingleSelectFilter
  } = Filters;
const { DropDownEditor } = Editors;
const approvalTypes = [
    { id: "Active", value: "Active" },
    { id: "Inactive", value: "Inactive" }
];
const ApprovalTypeEditor = <DropDownEditor options={approvalTypes} />;

class AdminHome extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
            tableNumber: 1,
            profileUser: this.props.user,
            profileUserSubmissions: null,
            colleges: props.colleges,
            currentClassId: null,
            showAddClass: false,
            wizardStep: 1,
            userSearch: '',
            userPagination: 0,
            resultsPerPage: 9,
            originalUserRows: {},
            initialUserRows: this.props.users,
            userRows: this.props.users,
            userRowsLength: this.props.users.length,
            userPageNumber: 1,
            showFilterModal: false,
            filters: {},
            filteredRows:  {},
            showRedoData: false,
            collegeDataGridKey: 'college',
            failedRecords: []
        }

        this.prepareUsers = this.prepareUsers.bind(this)
        this.exportUsers = this.exportUsers.bind(this)

        if (!props.user._id) {
            this.state.fetching = true
            props.dispatch(authUser(() => {
                if (this.props.user.role === 'admin') {
                    this.setState({ fetching: false })
                } else if (this.props.user.approved) {
                    props.history.push('/profile')
                } else {
                    props.history.push(`/signup/${this.props.user.intakeTab || 'welcome'}`)
                }
            }, () => {
                props.history.push('/signup/login?account')
            }))
        } else {
            if (this.props.user.role !== 'admin') {
                if (this.props.user.approved) {
                    props.history.push('/profile')
                } else {
                    props.history.push(`/signup/${this.props.user.intakeTab || 'welcome'}`)
                }
            }
        }
    }

    getRows(rows, filters) {
        const filteredRows = selectors.getRows({ rows, filters });
        return filteredRows
    }

    handleFilterChange(filter){
        const {filters} = this.state
        const newFilters = { ...filters }
        if (filter.filterTerm) {
          newFilters[filter.column.key] = filter
        } else {
          delete newFilters[filter.column.key]
        }
        
        return newFilters
      };

    removeFilter(filter){
        const {filters} = this.state
        const newFilters = {...filters}
        delete newFilters[filter.column.key]
        this.setState({filters: newFilters})
    }


    componentDidMount() {
        this.props.dispatch(fetchUsers(false)).then(() => this.prepareUsers())
    }

    prepareUsers(){
        const encapsulate = answer => {
            if (!answer || !answer.includes) answer = ''
            return answer.includes(',') ? `'${answer}'` : answer
        }
        const encapsulateAll = answers => {
            if (!answers || !answers.map) answers = []
            return answers.map(encapsulate)
        }

        const getEducation = (user) => {
            return _.map(user.education, item => {
                return item.school + ", " + item.subject + ", " + item.degree + ", " + item.graduationYear + "\n"
            })
        }

        const getWorkHistory = (work_history) => {
            return _.map(work_history, item => {
                return item.company + ", " + item.title + ", " + formatStringToDate(item.startDate) + ", " + formatStringToDate(item.endDate) + "\n"
            })
        }

        const getFiles = (files) => {
            //client request files in this order
            const resume = _.chain(files).find({ fileType: "resume"}, {}).get("fileUrl").value()
            const bio = _.chain(files).find({ fileType: "bio"}, {}).get("fileUrl").value()
            const project_summaries = _.chain(files).find({ fileType: "project_summaries"}, {}).get("fileUrl").value()
            const urls = _.filter([resume, bio, project_summaries], url => url !== undefined)
            return urls.join(",")
        }

        const getConsideringIC = user => {
            if(user.current_ic === true)
                return ''

            // const answers = []
            // if(user.considering_ic === true)
            //     answers.push("In the next 6 months")
            // else
            //     answers.push("Not in the next 6 months")

            // if(user.full_time === true)
            //     answers.push("Interested in FT")
            // else
            //     answers.push("Not interested in FT")
            
            // return answers.join()    
            
            if(user.considering_ic === true) {
                return "In the next 6 months"
            } else {
                if(user.full_time === true)
                    return "Interested in FT"
                else
                    return "Not interested in FT"

                // return "Not in the next 6 months"
            }
        }

        const getExperiences = user => {
            if (user.leadership_dev_prog)
                user.contextual_experience.push('HRLP')
                
            return encapsulateAll(user.contextual_experience).join(",")

        }

        const getIndustry = user => {
            // //adds to industry experience based on other answers

            // if(user.billable_work === true)
            //     user.industry_experience.push("Consulting (External)")
            // // else
            // //     user.industry_experience.push("n/a")

            // if(user.corporate_experience === true && user.billable_work === true)
            //     user.industry_experience.push("Consulting - External & Industry Mix")
            // else if (user.corporate_experience === false && user.billable_work === true)
            //     user.industry_experience.push("Consulting - External (Client Facing)")

            return encapsulateAll(user.industry_experience).join(",")

        }

        const getDifferentiators = user => {
            const answers = []

            if(user.internal_consulting === true)
                answers.push("Interal Consultant")

            if(user.leadership_dev_prog === true)
                answers.push("HRLD")

            return answers.join(",")
        }

        const getAvailability = user => {
            if(user.full_time === true)
                return "Full Time"
            
            if(user.part_time === true)
                return "Part Time"

            if(user.available_date !== undefined)
                return 'Upcoming (within 6 weeks)'
                // return `Upcoming (${moment(user.available_date).format("DD-MMM-YYYY")})`

            return 'Not Currently'
        }

        const getTravel = user => {
            const travel  = {
                "No travel": 'None',
                "Up to 20%": 'Light (1-2 night/wk or 1 wk/mo)',
                "Up to 50%": 'Moderate (2-3 night/wk or 2 wk/mo)',
                "80% travel": 'Road Warrior (4 night/wk, every wk)'
            }
            return encapsulate(travel[user.travel])
        }

        const getOnsite = user => {
            const on_site = {
                "Yes": 'Available',
                "Sometimes": 'Sometimes/As Needed',
                "No": 'All Remote'
            }
            return on_site[user.on_site]
        }

        const getHowLongIc = user => {
            let how_long_ic = user.how_long_ic;
            
            if(how_long_ic === null || how_long_ic === undefined)
                return null

            how_long_ic = how_long_ic.replace("<", "Less than")
            how_long_ic = how_long_ic.replace(">", "More than")
            return how_long_ic
        }

        const getHowLongBc = user => {
            let how_long_bc = user.how_long_bc;
            
            if(how_long_bc === null || how_long_bc === undefined)
                return null

            how_long_bc = how_long_bc.replace("<", "Less than")
            how_long_bc = how_long_bc.replace(">", "More than")
            return how_long_bc
        }

        const validateData = user => {
            let user_ok = true
            let errors = []
            _.map(validate, (value, key) => {
                if(_.isArray(value) && user[key] !== undefined && user[key] !== "" && key !== 'travel' && user[key] !== null){
                    const answers = _.isArray(user[key]) ? user[key] : user[key].split(',')
                    _.each(answers, answer => {
                        if (/^'.*'$/.test(answer)) answer = answer.substring(1, answer.length - 1)
                        if(!value.includes(answer.trim()) && !["Not Applicable", "HRLP"].includes(answer.trim())){
                            errors.push(key + ": " + answer.trim()+"\n")
                            user_ok = false
                        }
                    })
                }
            })

            if(!user_ok){
                this.setState(function (state, props) {
                    return { failedRecords: _.concat(state.failedRecords, {...user, errors}) }
                })
            }
            return user_ok
        }

        

        const users = _.chain(this.props.users)
            .map(user => ({
                ...user,
                firstNameFormatted: encapsulate(user.firstName),
                lastNameFormatted: encapsulate(user.lastName),
                businessFormatted: encapsulate(user.business),
                createdAtFormatted: moment(user.createdAt).format('DD-MMM-YYYY'),
                updatedAtFormatted: moment(user.updatedAt).format('DD-MMM-YYYY'),
                approved: user.approved === true ? "Active" : "Inactive",
                position_title: 'Independent Consultant',
                company: 'Independent Consultant',
                current_status: user.current_ic === true ? "Yes" : "No",
                current_ic: user.current_ic === true ? "Yes" : "No",
                // industry: getIndustry(user),
                considering_ic: getConsideringIC(user),
                // differentiators: getDifferentiators(user),
                degree: '',
                website: user.linkedin_url,
                tags: '',
                job: '',
                workflow_step: '',
                more_info: user.more_info !== undefined ? encapsulate(user.more_info).replace(/"/g, "'").replace(/#/g, ' ') : '',
                company_size: encapsulateAll(user.company_size).join(","),
                consulting_formatted: encapsulateAll(user.consulting).join(","),
                experiences_formated: getExperiences(user),
                functional_experience_formatted: encapsulateAll(user.functional_experience).join(","),
                primary_experience_formatted: encapsulateAll(user.primary_experience).join(","),
                secondary_experience_formatted: encapsulateAll(_.filter(user.secondary_experience, item => item !== "Not Applicable")).join(","),
                industry_experience_formatted: getIndustry(user),
                technology_experience_formatted: encapsulateAll(_.filter(user.technology_experience, item => item !== "Not Applicable")).join(","),
                available_date: parseToDate(user.available_date),
                availability: getAvailability(user),
                work_history: getWorkHistory(user.work_history),
                education: getEducation(user),
                files: getFiles(user.files),
                travel_formatted: getTravel(user),
                travel: getTravel(user),
                on_site: getOnsite(user),
                how_long_ic: getHowLongIc(user),
                how_long_bc: getHowLongBc(user)
            }))
            .flattenDeep()
            .orderBy('email')
            .value()
            .filter(user => validateData(user))

        this.setState({
            originalUserRows: users,
            initialUserRows: users,
            userRows: users,
            userRowsLength: users.length,
            usersDataGridKey: Math.random()
        }, () => {
            const filteredRows = this.getRows(this.state.originalUserRows, this.state.filters)
            this.setState({filteredRows})
        })
    }
    
    handleUserSearch = (event) => {
        const columns = [
          {
            key: 'lastName',
            name: 'Last Name',
            filterable: true,
            sortable: true
          },
          {
            key: 'firstName',
            name: 'First Name',
            resizable: true,
            filterable: true,
            sortable: true
          },
          {
            key: 'email',
            name: 'Email',
            filterable: true,
            sortable: true
          },
          {
            key: 'approved',
            name: 'Status',
            filterable: true,
            sortable: true
          }
        ];

        this.setState({userSearch: event.target.value});
  
        if(event.target.value.length > 1) {
          const filterableCols = _.filter(columns, {filterable: true});
            
          let filteredRows = [];
          _.each(this.state.originalUserRows, (el, index) => {
            _.each(filterableCols, (filter) => {
              if(_.isString(el[filter.key]) && _.includes(el[filter.key].toLowerCase(), event.target.value.toLowerCase()) && !_.includes(filteredRows, el)) {
                filteredRows.push(el);
              } else if(_.includes(el[filter.key], event.target.value) && !_.includes(filteredRows, el)) {
                filteredRows.push(el);
              }
            })
          });
          this.setState({
            initialUserRows: filteredRows,
            userRows: filteredRows,
            userRowsLength: filteredRows.length,
            originalUserRows: filteredRows,
            filteredRows
          });  
        } else if(event.target.value.length === 0) {
          this.setState({
            initialUserRows: this.state.originalUserRows,
            userRows: this.state.originalUserRows,
            userRowsLength: this.state.originalUserRows.length,
          });
          this.prepareUsers()
        }
      }

      handlePagination = (direction) => {
        if(direction === "next") {
            // reset the userRows state to be a slice of the users table that matches the page number
            this.setState({userRows: this.state.initialUserRows.slice(this.state.resultsPerPage * this.state.userPageNumber, this.state.resultsPerPage * (this.state.userPageNumber + 1))});
            // increase the page number for future reference
            this.setState({userPageNumber: this.state.userPageNumber + 1})
        } else if(direction === "prev") {
            // reset the userRows state to be a slice of the users table that matches the page number
            this.setState({userRows: this.state.initialUserRows.slice(this.state.resultsPerPage * (this.state.userPageNumber - 2), this.state.resultsPerPage * (this.state.userPageNumber - 1))});
            // increase the page number for future reference
            this.setState({userPageNumber: this.state.userPageNumber - 1})
        }
      }
    
    sortRows(sortColumn, sortDirection){
        const rows = _.sortBy(this.state.filteredRows, [sortColumn])
        if(sortDirection === 'DESC')
            _.reverse(rows)
        this.setState({ 
            filteredRows: rows,
        })
    }

    
    convertRowsToCSV(args) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
         
        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }
         
        columnDelimiter = '","';
        lineDelimiter = "\"\n\"";

         
        keys = Object.keys(data[0]);
         
        result = '"';
         
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                if (ctr > 0) result += columnDelimiter;
                
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        
        return result;
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const rows = this.state.filteredRows.slice();
        for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated };
        }
            
        this.setState({filteredRows: rows})
            
        rp(`/api/users/${this.state.filteredRows[fromRow]._id}`, {
            method: 'PATCH',
            body: { approved: updated.approved === "Active" ? true : false }
        })
    };

    exportUsers(args) {
        var data, filename, link;
        
        const exportColumns = [
            { key: 'firstNameFormatted', name: 'First Name' },
            { key: 'lastNameFormatted', name: 'Last Name' },
            { key: 'position_title', name: 'Position Title' },
            { key: 'company', name: 'Company'},
            { key: 'email', name: 'Email (Home)' },
            { key: 'businessFormatted', name: 'Business Name' },
            { key: 'current_ic', name: 'Current IC?' },
            { key: 'considering_ic', name: 'Interest in Becoming an IC' },
            { key: 'how_long_ic', name: 'Years as IC' },
            // { key: 'industry', name: 'Industry' },
            { key: 'consulting_formatted', name: 'Human Capital Consulting' },
            { key: 'how_long_bc', name: 'Years as External Consultant' },
            // { key: 'differentiators', name: 'Differentiators' },
            { key: 'primary_experience_formatted', name: 'Primary Expertise' },
            { key: 'secondary_experience_formatted', name: 'Secondary Expertise' },
            { key: 'industry_experience_formatted', name: 'Industry' },
            { key: 'technology_experience_formatted', name: 'Technologies' },
            { key: 'functional_experience_formatted', name: 'Business Function' },
            { key: 'experiences_formatted', name: 'Experiences' },
            { key: 'travel_formatted', name: 'Travel' },
            { key: 'on_site', name: 'On Site' },
            { key: 'zip_code', name: 'Home Postal Code' },
            { key: 'availability', name: 'Availability' },
            { key: 'current_status', name: 'Current Status' },
            { key: 'phone_number', name: 'Mobile Phone' },
            { key: 'degree', name: 'Advanced Degree/Top Schools' },
            { key: 'website', name: 'Website' },
            { key: 'score', name: 'GigHR Score' },
            { key: 'more_info', name: 'Differentiators (from GigHR)' },
            // { key: 'more_info', name: 'Anything else? (from GigHR)' },
            { key: 'createdAtFormatted', name: 'Created Date' },
            { key: 'updatedAtFormatted', name: 'Most recent GIgHR.com Update' },
            { key: 'blurb', name: 'Summary Headline (from IC Profile)'},
            { key: 'education', name: 'Education - Full Table'},
            { key: 'files', name: 'Files' },
            { key: 'work_history', name: 'Work Experience - Full Table'},
            { key: 'job_id', name: 'Job ID', value: '723'},
            { key: 'workflow_step', name: 'Workflow Step', value: 'Sourced-OnPoint-New'}
            // { key: 'tags', name: 'Tags' }
        ]

        const exportDataRows = _.map(this.state.userRows, row => {
            return _.map(exportColumns, column => {
                if(column.value !== undefined)
                    return column.value
                return row[column.key]
            })
        })
        
        var csv = this.convertRowsToCSV({data:exportDataRows});
        if (csv == null) return;
        
        const headerRow = _.map(exportColumns, column => column.name)
        
        csv = '"' + headerRow.join('","') + "\"\n" + csv
        csv = csv.replace(/null/g, '')
        csv = csv.replace(/undefined/g, '')
        
        filename = `export.csv`;
         
        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        data = encodeURI(csv);
         
        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        link.click();
    }

    render() {
        
        const columns = [
            {
                key: 'firstName',
                name: 'First Name',
                resizable: true,
                sortable: true,
                width: 125
            },
            {
                key: 'lastName',
                name: 'Last Name',
                resizable: true,
                sortable: true,
                width: 125
            },
            {
                key: 'email',
                name: 'Email',
                resizable: true,
                sortable: true,
                width: 200
            },
            {
                key: 'phone_number',
                name: 'Phone',
                resizable: true,
                sortable: true,
                width: 150
            },
            {
                key: 'score',
                name: 'Logic Score',
                resizable: true,
                sortable: true,
                width: 175,
                filterable: true,
                filterRenderer: NumericFilter
            },
            {
                key: 'createdAt',
                name: 'Created Date',
                resizable: true,
                sortable: true,
                width: 110,
                formatter: props => moment(props.row.createdAt).format('DD-MMM-YYYY')
            },
            {
                key: 'updatedAt',
                name: 'Last Updated',
                resizable: true,
                sortable: true,
                width: 110,
                formatter: props => moment(props.row.updatedAt).format('DD-MMM-YYYY')
            },
            {
                key: 'approved',
                name: 'Status',
                resizable: true,
                width: 125,
                sortable: true,
                editor: ApprovalTypeEditor
            },
            {
                key: '',
                name: 'Actions',
                resizable: true,
                width: 75,
                formatter: (props) => {
                    return (
                        <a
                            style={{cursor:'pointer', textAlign:'center'}} 
                            href={`/profile/${props.row._id}`}
                            target="_blank"
                        >
                            <i className="far fa-arrow-alt-circle-right"></i>
                        </a>
                    )
                }
            }
        ];

        const failed_columns = [
            {
                key: 'firstName',
                name: 'First Name',
                resizable: true,
                sortable: true,
                width: 125
            },
            {
                key: 'lastName',
                name: 'Last Name',
                resizable: true,
                sortable: true,
                width: 125
            },
            {
                key: 'email',
                name: 'Email',
                resizable: true,
                sortable: true,
                width: 200
            },
            {
                key: 'phone_number',
                name: 'Phone',
                resizable: true,
                sortable: true,
                width: 150
            },
            // {
            //     key: 'score',
            //     name: 'Logic Score',
            //     resizable: true,
            //     sortable: true,
            //     width: 175,
            //     filterable: true,
            //     filterRenderer: NumericFilter
            // },
            {
                key: 'createdAt',
                name: 'Created Date',
                resizable: true,
                sortable: true,
                width: 110,
                formatter: props => moment(props.row.createdAt).format('DD-MMM-YYYY')
            },
            {
                key: 'updatedAt',
                name: 'Last Updated',
                resizable: true,
                sortable: true,
                width: 110,
                formatter: props => moment(props.row.updatedAt).format('DD-MMM-YYYY')
            },
            // {
            //     key: 'approved',
            //     name: 'Status',
            //     resizable: true,
            //     width: 125,
            //     sortable: true,
            //     editor: ApprovalTypeEditor
            // },
            {
                key: 'errors',
                name: 'Errors',
                resizable: true,
                width: 500,
                formatter: (props) => {
                    return (
                        <div onClick={() => alert(props.row.errors)}>{_.join(props.row.errors)}</div>
                    )
                }
            }
        ];
        
        // const pager = () => (
        //     <div className="row padding-top-medium">
        //         <div className="col-xs-12 col-sm-6">
        //         <Pager>
        //             <Pager.Item previous disabled={this.state.userPageNumber === 1} href="#" eventKey={'userPrev'} onClick={() => this.handlePagination('prev', 'user')}>
        //                 <FontAwesomeIcon size="2x" icon={faAngleLeft} fixedWidth/>
        //             </Pager.Item>
        //             <Pager.Item next disabled={this.state.userPageNumber === Math.ceil(this.state.initialUserRows.length/this.state.resultsPerPage)} href="#" eventKey={'userNext'} onClick={() => this.handlePagination('next', 'user')}>
        //                 <FontAwesomeIcon size="2x" icon={faAngleRight} fixedWidth/>
        //             </Pager.Item>
        //             <li className="intro margin-vert-zero text-small">Page {this.state.userPageNumber} of {Math.ceil(this.state.initialUserRows.length/this.state.resultsPerPage)}</li>
        //         </Pager>
        //         </div>
        //     </div>
        // )

        const rowIndicator = (title) => (
            <div className="row" style={{marginTop: 30}}>
                <div className="col-xs-9 padding-bottom-large">
                    {/* <h2 className="intro margin-vert-zero" style={{lineHeight: '20px', marginTop: '5px !important'}}>{title}<br /><small>(Showing {(this.state.resultsPerPage * (this.state.userPageNumber - 1) + 1)}-{this.state.userRows.length < this.state.resultsPerPage ? (this.state.resultsPerPage * (this.state.userPageNumber - 1)) + this.state.userRows.length : this.state.userRows.length * this.state.userPageNumber} of {this.state.userRowsLength} users)</small></h2> */}
                    <h2 className="intro margin-vert-zero" style={{lineHeight: '20px', marginTop: '5px !important'}}>{title} <small>(Count: {this.state.userRows.length})</small></h2>
                </div>
                <div className="task col-xs-3 padding-vert-zero padding-horiz-medium margin-all-zero">
                    <FormControl
                        type="text"
                        value={this.state.userSearch}
                        placeholder="Search"
                        onChange={this.handleUserSearch} />
                </div>
            </div>
        )

        const CurrentFilters = () => {
            let hasFilters = false
            let filters = (
                <div style={{display:'inline-block', float:'left', marginRight:20, marginTop:20}}>
                    {
                        _.map(this.state.filters, filter =>
                            <span key={filter.column.key} style={{border:'1px solid #ddd', background:'#f9f9f9', padding:'3px 5px',marginRight:5}}>
                                <strong>{filter.column.name}:</strong>  
                                {
                                    _.map(filter.filterTerm, term => {
                                        hasFilters = true
                                        return (
                                            <span key={term.value} style={{margin:'0 3px'}}>
                                                {term.value} 
                                            </span>
                                        )
                                    })
                                }
                                <FontAwesomeIcon size="1x" icon={faTimesCircle} fixedWidth style={{cursor:'pointer'}} 
                                    onClick={() => this.removeFilter(filter)} />
                            </span>
                        )
                    }
                </div>
            )
            return hasFilters ? filters : null
        }
        
        // const filteredRows = this.getRows(this.state.originalUserRows, this.state.filters);
        if( this.state.filteredRows.length === undefined)
            return <div style={{background:"#fff"}}></div>

        return (
            <Grid fluid>
                <Col sm={2} style={{color:"#fff"}}>
                    <h3>Menu</h3>
                    <div className="list-group">
                        <a href="#" className="list-group-item list-group-item-action">Admin</a>
                    </div>
                </Col>
                <Col sm={10} style={{height:'100vh', background:'#fff', overflow:'auto'}}>
                    <div className="row admin-tab">
                        <div className="col-xs-12">
                            {rowIndicator("Users")}
                            <div className="row">
                                <div className="col-xs-12 margin-top-large">
                                    <ReactDataGrid
                                        key={this.state.usersDataGridKey}
                                        minHeight={500}
                                        columns={columns}
                                        rowGetter={i => this.state.filteredRows[i]}
                                        rowsCount={this.state.filteredRows.length}
                                        onGridSort={(sortColumn, sortDirection) => {this.sortRows(sortColumn, sortDirection)}}
                                        // toolbar={<Toolbar enableFilter={true}><CurrentFilters /></Toolbar>}
                                        // onAddFilter={filter => this.setState({filters: this.handleFilterChange(filter)})}
                                        // onClearFilters={() => this.setState({filters: {}})}
                                        // getValidFilterValues={columnKey => getValidFilterValues(this.state.originalUserRows, columnKey)}
                                        onGridRowsUpdated={this.onGridRowsUpdated}
                                        enableCellSelect={true}
                                    />
                                    <Button onClick={() => this.exportUsers()} style={{float:'right', margin:'15px 0'}}>Export</Button>
                                    
                                    {this.state.failedRecords.length > 0 &&
                                        <div style={{clear:'both'}}>
                                            <h1>Non-Exportable Records</h1>
                                            <ReactDataGrid
                                                key={Math.random()}
                                                minHeight={200}
                                                columns={failed_columns}
                                                rowGetter={i => this.state.failedRecords[i]}
                                                rowsCount={this.state.failedRecords.length}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Grid>
        )
    }
};


AdminHome.contextTypes = {
    store: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
}
AdminHome.propTypes = {
    dispatch: PropTypes.func.isRequired,
}



const composeComponent = R.compose(
    connect((state) => ({
        user: state.user,
        ...state.admin_home,
        users: state.admin_home.users
        // colleges: state.college_choice.colleges,
        // professions: state.module_overview.professions
    })),
    defaultProps({
        users: []
    }),
    mapProps((props) => ({
        ...props,
        // submissions: props.users
    }))
)

export default composeComponent(AdminHome);
