import { createStore, applyMiddleware, combineReducers } from 'redux';
import path from 'path';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import _ from 'lodash';

const reducers = window.reducers = {
    ...require.context('./feature-components', true, /reducer\.js$/)
        .keys()
        .reduce((acc, key) => {
            const newKey = _.snakeCase(path.basename(path.join(key, '../'), '.js'));
            return { ...acc, [newKey]: require(`./feature-components/${key.replace('./', '')}`).default };
        }, {}),
    user: _=>_||{}
};


const appReducers = combineReducers(reducers);
const rootReducer = (state,action) => {
    if (['USER_LOGOUT', 'LOGOUT_USER_SUCCESS'].includes(action.type))
    {
        state = undefined;
    }
    if (['LOGIN_USER_SUCCESS', 'UPDATE_USER_SUCCESS', 'AUTH_USER_SUCCESS', 'CREATE_USER_SUCCESS', 'LINKEDIN_LOGIN_SUCCESS'].includes(action.type))
    {
        state = { ...state, user: action.value }
    }
    return appReducers(state,action);
}    


export default createStore(rootReducer, applyMiddleware(thunk, logger));