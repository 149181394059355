import _ from 'lodash'
require('request');
const rp = require('request-promise').defaults({ baseUrl: window.location.origin, json: true });


export const fetchUsers = () => async (dispatch) => { 
  dispatch({ type: 'FETCH_USERS_PENDING' });
  try {
      let users = await rp(`/api/users`)
      users = _.mapKeys(users.users, (value, key) =>  value._id)
      return dispatch({ type: 'FETCH_USERS_SUCCESS', value: users });
  } catch(err){
      return dispatch({ type: 'FETCH_USERS_ERROR', error: err });
  }
}

export const authUser = (resolve, reject) => dispatch => {
  dispatch({ type: 'AUTH_USER_PENDING' })
  rp('/api/users/login', { method: 'POST', body: {} })
    .then(res => {
      if (res.success) {
        dispatch({ type: 'AUTH_USER_SUCCESS', value: res.user })
        return resolve()
      } else {
        dispatch({ type: 'AUTH_USER_ERROR', error: res })
        return reject()
      }
    })
    .catch( err=> {
      dispatch({ type: 'AUTH_USER_ERROR', error: err.message })
      return reject()
    })
}
