import React from 'react'

export const Micons = (props) => {
    const fill = props.fill || "#c89513"
    const width = props.width || 30
    const height = props.height || 30
    const style = props.style || ''

    switch(props.name){
        case "circle-arrow-right":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height}>
                    <path d="M15.71,12.71a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-3-3a1,1,0,0,0-1.42,1.42L12.59,11H9a1,1,0,0,0,0,2h3.59l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0ZM22,12A10,10,0,1,0,12,22,10,10,0,0,0,22,12ZM4,12a8,8,0,1,1,8,8A8,8,0,0,1,4,12Z" fill={fill} />
                </svg>
            )
        case "welcome-hands":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path fill={fill} d="M616 96h-48c-7.107 0-13.49 3.091-17.884 8H526.59l-31.13-36.3-.16-.18A103.974 103.974 0 0 0 417.03 32h-46.55c-17.75 0-34.9 4.94-49.69 14.01C304.33 36.93 285.67 32 266.62 32h-32.11c-28.903 0-57.599 11.219-79.2 32.8L116.12 104H89.884C85.49 99.091 79.107 96 72 96H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h48c10.449 0 19.334-6.68 22.629-16h18.801l75.35 67.57c25.542 26.45 59.925 44.43 96.58 44.43 16.39 0 32.28-3.85 46.1-10.93 24.936.496 51.101-10.368 69.07-31.41 19.684-5.579 37.503-17.426 50.72-34.6 20.989-4.401 40.728-16.492 53.42-35.06h40.701c3.295 9.32 12.18 16 22.629 16h48c13.255 0 24-10.745 24-24V120c0-13.255-10.745-24-24-24zM48 352c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16zm412.52-5.76c-15.35 14.295-36.884 11.328-39.95 8 1.414 13.382-18.257 41.043-49.08 38.88-5.541 18.523-28.218 33.826-51.49 25.75-8.89 8.89-22.46 13.13-34.64 13.13-24.95 0-47.77-14.54-63.14-30.91l-81.3-72.91a31.976 31.976 0 0 0-21.36-8.18H96V152h26.75c8.48 0 16.62-3.37 22.62-9.37l43.88-43.88A64.004 64.004 0 0 1 234.51 80h32.11c5.8 0 11.51.79 17 2.3l-43.27 50.49c-23.56 27.48-23.84 67.62-.66 95.44 32.388 38.866 91.378 39.228 124.48 1.98l25.98-30.08L462.59 296c13.44 14.6 10.95 38.13-2.07 50.24zM544 320h-24.458c.104-20.261-6.799-39.33-19.762-54.4L421.7 162.28c4.51-9.51 2.34-21.23-6.01-28.45-10.075-8.691-25.23-7.499-33.86 2.48l-53.63 62.12c-13.828 15.41-38.223 15.145-51.64-.93a25.857 25.857 0 0 1 .23-33.47l57.92-67.58A47.09 47.09 0 0 1 370.48 80h46.55c16.11 0 31.44 6.94 42.07 19.04L504.52 152H544v168zm48 32c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16z"/>
                </svg>
            )
        case "concentric-circles":
            return (
                <svg xmlns="http://www.w3.org/2000/svg"  width={width} height={height} viewBox="0 0 22 14">
                    <path fill={fill} id="Icon" d="M14.666,14A7.6,7.6,0,0,1,11,13.061,7.594,7.594,0,0,1,7.333,14,7.181,7.181,0,0,1,0,7,7.181,7.181,0,0,1,7.333,0,7.584,7.584,0,0,1,11,.94,7.59,7.59,0,0,1,14.666,0,7.181,7.181,0,0,1,22,7,7.181,7.181,0,0,1,14.666,14ZM7.333.778A6.383,6.383,0,0,0,.815,7a6.383,6.383,0,0,0,6.519,6.222,6.714,6.714,0,0,0,2.9-.649,6.808,6.808,0,0,1,0-11.146A6.715,6.715,0,0,0,7.333.778Zm6.244,8.007-3.111,2.969c.174.14.349.267.534.387A6.3,6.3,0,0,0,13.577,8.785Zm.016-3.515L8.847,9.8a6.277,6.277,0,0,0,1.03,1.417l3.957-3.775c.009-.139.018-.288.018-.439A5.971,5.971,0,0,0,13.594,5.27Zm-1.28-2.279L8.148,6.965V7A5.926,5.926,0,0,0,8.5,9.024l4.779-4.56A6.2,6.2,0,0,0,12.314,2.991ZM11,1.859A6.2,6.2,0,0,0,8.284,5.735L11.75,2.428A6.866,6.866,0,0,0,11,1.859Z" />
                </svg>
            )
        case "smile":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30.194">
                    <path id="Op_component_5" data-name="Op component 5" d="M302,89.027a15.1,15.1,0,1,0,15,15.1,15.066,15.066,0,0,0-15-15.1Zm0,2.273a12.831,12.831,0,1,1-12.755,12.824A12.76,12.76,0,0,1,302,91.3Zm-4.5,7.917a1.511,1.511,0,1,0,1.5,1.511,1.513,1.513,0,0,0-1.5-1.511Zm9.008,0a1.511,1.511,0,1,0,1.488,1.511,1.5,1.5,0,0,0-1.488-1.511Zm-11,9.454a1.122,1.122,0,0,0,.106,1.565,9.238,9.238,0,0,0,12.781,0,1.121,1.121,0,0,0,.093-1.565,1.092,1.092,0,0,0-1.621-.094,7,7,0,0,1-9.726,0,1.086,1.086,0,0,0-1.634.094Z" transform="translate(-287 -89.027)" fill={fill} />
                </svg>
            ) 
        case "availability":
        case "calendar":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} data-name="Layer 1" viewBox="0 0 24 24">
                    <path fill={fill} d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z"/>
                </svg>
            )
        case "background":
        case "book":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 30.194">
                    <path id="Op_component_1" data-name="Op component 1" d="M1292.867,93.093h-3.4V90.718a1.192,1.192,0,0,0-.649-1.074,7.562,7.562,0,0,0-3.173-.618,10.351,10.351,0,0,0-3.559.655A19.858,19.858,0,0,0,1277,92.763a19.854,19.854,0,0,0-5.089-3.081,10.314,10.314,0,0,0-3.556-.653,7.558,7.558,0,0,0-3.173.617,1.19,1.19,0,0,0-.649,1.073v2.375h-3.4A1.162,1.162,0,0,0,1260,94.281v23.752a1.162,1.162,0,0,0,1.133,1.187h31.734a1.162,1.162,0,0,0,1.133-1.187V94.281A1.162,1.162,0,0,0,1292.867,93.093Zm-10.03-1.171a8.15,8.15,0,0,1,2.808-.52,6.582,6.582,0,0,1,1.557.18V112.9a9,9,0,0,0-1.557-.134,10.342,10.342,0,0,0-3.559.655c-.335.124-.672.271-1,.433-.114.055-.226.118-.339.175-.218.111-.434.222-.646.344-.129.073-.251.148-.378.224-.189.114-.376.227-.558.345-.127.082-.247.16-.368.243-.169.113-.33.224-.487.335-.055.038-.117.079-.169.117V94.846A18.4,18.4,0,0,1,1282.837,91.923Zm-16.037-.34a6.59,6.59,0,0,1,1.557-.18,8.156,8.156,0,0,1,2.8.518,18.5,18.5,0,0,1,4.706,2.925v20.791c-.049-.036-.109-.075-.162-.111-.168-.12-.34-.237-.523-.36-.113-.076-.225-.15-.343-.225-.188-.122-.38-.238-.576-.356-.121-.073-.239-.145-.362-.214-.213-.124-.431-.234-.652-.348-.111-.056-.221-.118-.334-.173-.332-.159-.668-.307-1-.431a10.345,10.345,0,0,0-3.555-.653,9.006,9.006,0,0,0-1.557.134V91.583Zm-4.533,3.886h2.267v18.99a1.28,1.28,0,0,0,.522,1,1.111,1.111,0,0,0,.312.14,1.07,1.07,0,0,0,.221.045,1.051,1.051,0,0,0,.354-.038,1.107,1.107,0,0,0,.2-.063,5.334,5.334,0,0,1,1.729-.378c.153-.011.314-.017.485-.017a8.156,8.156,0,0,1,2.8.519,10.1,10.1,0,0,1,1.064.478c.094.047.187.1.28.147.287.153.57.317.845.486l.121.073h-11.2Zm29.467,21.378h-11.205c.038-.026.079-.048.118-.072.272-.17.556-.333.844-.487.094-.05.189-.1.284-.149a10.293,10.293,0,0,1,1.063-.476,8.143,8.143,0,0,1,2.808-.52,5.707,5.707,0,0,1,2.206.39,1.092,1.092,0,0,0,1.091-.071,1.2,1.2,0,0,0,.525-1V95.469h2.267Z" transform="translate(-1260 -89.027)" fill={fill} />
                </svg>
            )
        case "consulting":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 42.5 33.716">
                    <g id="Group_1" data-name="Group 1" transform="translate(-440.25 -87.266)">
                        <path id="Op_component_6" data-name="Op component 6" d="M474.532,101.9H464.31a7.317,7.317,0,0,0-1.754.221A7.518,7.518,0,0,0,463.967,101a7.624,7.624,0,0,0,2.189-5.373,7.545,7.545,0,0,0-7.467-7.6H448.467a7.613,7.613,0,0,0-.886,15.154l-.435,2.278a.758.758,0,0,0,.289.751.74.74,0,0,0,.436.146.729.729,0,0,0,.35-.091l5.543-3.032h4.926a7.374,7.374,0,0,0,1.758-.221,7.631,7.631,0,0,0-3.6,6.5,7.545,7.545,0,0,0,7.467,7.6h4.924l5.546,3.034a.729.729,0,0,0,.783-.058.763.763,0,0,0,.289-.75l-.435-2.279a7.613,7.613,0,0,0-.885-15.154Zm-15.844.327h-5.11a.24.24,0,0,0-.117.03l-5.249,2.871.5-2.6a.251.251,0,0,0-.049-.2.245.245,0,0,0-.183-.095l-.286-.012a6.6,6.6,0,0,1,.277-13.2h10.223a6.55,6.55,0,0,1,6.482,6.6,6.62,6.62,0,0,1-1.9,4.665A6.389,6.389,0,0,1,458.687,102.225ZM474.806,116.1l-.286.012a.242.242,0,0,0-.183.1.252.252,0,0,0-.048.2l.5,2.6-5.249-2.872a.244.244,0,0,0-.117-.029h-5.11a6.6,6.6,0,0,1,0-13.2h10.222a6.6,6.6,0,0,1,.275,13.2Z" fill={fill}/>
                        <path id="Op_component_7" data-name="Op component 7" d="M475.127,120.982a1.463,1.463,0,0,1-.7-.176l-5.385-2.946H464.31a8.385,8.385,0,0,1-6.168-13.876l-.034.009.018-.011h-4.172l-5.376,2.94a1.492,1.492,0,0,1-2.169-1.613l.29-1.518a8.375,8.375,0,0,1,1.768-16.525h10.222a8.385,8.385,0,0,1,6.166,13.878l.035,0-.006,0h9.647a8.375,8.375,0,0,1,1.767,16.524l.29,1.522a1.526,1.526,0,0,1-.581,1.5A1.47,1.47,0,0,1,475.127,120.982ZM464.31,103.655a5.848,5.848,0,0,0,0,11.693h5.11a.993.993,0,0,1,.473.12l3.856,2.11-.2-1.028a1.011,1.011,0,0,1,.194-.811.985.985,0,0,1,.742-.384l.286-.012a5.85,5.85,0,0,0-.244-11.688ZM448.466,89.779a5.85,5.85,0,0,0-.245,11.687l.285.012a.988.988,0,0,1,.74.38,1.01,1.01,0,0,1,.2.814l-.2,1.028,3.855-2.108a1,1,0,0,1,.475-.122h5.11a5.646,5.646,0,0,0,4.051-1.71,5.869,5.869,0,0,0,1.683-4.134,5.8,5.8,0,0,0-5.732-5.846Z" fill={fill}/>
                        <path id="Op_component_8" data-name="Op component 8" d="M448.085,93.473a1.755,1.755,0,1,0,1.723,1.754A1.741,1.741,0,0,0,448.085,93.473Zm0,2.506a.752.752,0,1,1,.738-.752A.746.746,0,0,1,448.085,95.979Z" fill={fill}/>
                        <ellipse id="Op_component_9" data-name="Op component 9" cx="2.47" cy="2.51" rx="2.47" ry="2.51" transform="translate(445.62 92.72)" fill={fill}/>
                        <path id="Op_component_10" data-name="Op component 10" d="M453.578,93.473a1.755,1.755,0,1,0,1.723,1.754A1.741,1.741,0,0,0,453.578,93.473Zm0,2.506a.752.752,0,1,1,.738-.752A.746.746,0,0,1,453.578,95.979Z" fill={fill}/>
                        <ellipse id="Op_component_11" data-name="Op component 11" cx="2.47" cy="2.51" rx="2.47" ry="2.51" transform="translate(451.11 92.72)" fill={fill}/>
                        <path id="Op_component_12" data-name="Op component 12" d="M459.071,93.473a1.755,1.755,0,1,0,1.723,1.754A1.741,1.741,0,0,0,459.071,93.473Zm0,2.506a.752.752,0,1,1,.738-.752A.746.746,0,0,1,459.071,95.979Z" fill={fill}/>
                        <ellipse id="Op_component_13" data-name="Op component 13" cx="2.47" cy="2.51" rx="2.47" ry="2.51" transform="translate(456.6 92.72)" fill={fill}/>
                    </g>
                </svg>
            )   
        case "corporate":
        case "building":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} data-name="Layer 1" viewBox="0 0 24 24">
                    <path  fill={fill} d="M14,8h1a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2Zm0,4h1a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2ZM9,8h1a1,1,0,0,0,0-2H9A1,1,0,0,0,9,8Zm0,4h1a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,8H20V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm-8,0H11V16h2Zm5,0H15V15a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1v5H6V4H18Z"/>
                </svg>
            )
        case "expertise":
        case "bullseye":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
                    <path fill={fill} d="M12,5a7,7,0,1,0,7,7A7,7,0,0,0,12,5Zm0,12a5,5,0,1,1,5-5A5,5,0,0,1,12,17Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13ZM12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"/>
                </svg>
            )
        case "times":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
                    <path fill={fill} d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/>
                </svg>
            )
        case "road":
        case "experience":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 494.889 494.889">
                    <g>
                        <g>
                            <polygon fill={fill} points="494.889,438.777 395.631,56.111 333.42,56.111 405.402,438.777"/>
                            <polygon fill={fill} points="89.486,438.777 161.467,56.111 99.257,56.111 0,438.777"/>
                        </g>
                        <g>
                            <polygon fill={fill} points="263.232,168.716 231.659,168.716 228.593,223.57 266.295,223.57"/>
                            <polygon fill={fill} points="257.105,59.009 237.782,59.009 234.719,113.863 260.169,113.863"/>
                            <g>
                                <polygon fill={fill} points="120.872,438.777 192.652,56.37 191.27,56.111 172.352,56.111 100.522,438.777"/>
                                <polygon fill={fill} points="394.365,438.777 322.535,56.111 303.617,56.111 302.236,56.37 374.016,438.777"/>
                            </g>
                            <polygon fill={fill} points="269.355,278.424 225.532,278.424 222.471,333.274 272.42,333.274"/>
                            <polygon fill={fill} points="278.113,438.777 275.48,388.129 219.407,388.129 216.774,438.777"/>
                        </g>
                    </g>
                </svg>
            )
        case "chevron-right":
        case "arrow-right":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} style={style}>
                    <path fill={fill} d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"/>
                </svg>
            )
        case "chevron-left":
        case "arrow-left":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} style={style}>
                    <path fill={fill} d="M11.29,12l3.54-3.54a1,1,0,0,0,0-1.41,1,1,0,0,0-1.42,0L9.17,11.29a1,1,0,0,0,0,1.42L13.41,17a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41Z"/>
                </svg>
            )
        default:
    }
}
