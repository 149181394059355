const rp = require('request-promise')
    .defaults({ baseUrl: window.location.origin, json: true });
    
/**
 * Hit backend to make sure that the password-reset token is still valid and not expired
 * @param {*} resetToken 
 */
export const verifyResetToken = (resetToken) => async (dispatch) => {
    dispatch({ type: 'SUBMIT_VERIFY_RESET_TOKEN_PENDING' });
    try {
        const verifyTokenResp = await rp(`/api/users/password-reset/${resetToken}/verify`, {
            method: 'GET',
            json: true
        });
        return dispatch({ type: 'SUBMIT_VERIFY_RESET_TOKEN_SUCCESS', value: verifyTokenResp.data });
    } catch (err) {
        return dispatch({
            type: 'SUBMIT_VERIFY_RESET_TOKEN_ERROR',
            error: err.error
        });
    }
};

/**
 * Submit new password to change user password
 * @param {*} password 
 * @param {*} passwordConfirm 
 * @param {*} resetToken 
 */
export const submitNewPassword = (password, passwordConfirm, resetToken) => async (dispatch) => {
    dispatch({ type: 'SUBMIT_RESET_PASSWORD_PENDING' });
    try {
        const resetResp = await rp(`/api/users/password-reset/${resetToken}`, {
            method: 'PUT',
            json: true,
            body: { password, passwordConfirm }
        });
        return dispatch({ type: 'SUBMIT_RESET_PASSWORD_SUCCESS', value: resetResp.data });
    } catch(err){
        return dispatch({ type: 'SUBMIT_RESET_PASSWORD_ERROR', error: err.error });
    }
};
