import React from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { FormGroup, FormControl, Button, InputGroup, HelpBlock } from 'react-bootstrap';
import { submitResetRequest } from './actions';



const EnterEmailForm = (props) => (
    <div className="container" style={{color:'#fff'}}>
        <div className="row">
            <div className='' style={{margin:'auto'}}>
                <form className='form-signin' style={{margin:'auto'}} onSubmit={props.handleSubmit}>
                    <div className="text-center">
                        <FormGroup
                            controlId="formBasicTextPassword"
                        >
                            <a href="/signup/login">
                                <img src="/img/gighr.png" alt="gig hr logo" width="250px" className="mb-10" />
                            </a>
                            <h4 className='text-center' style={{color:'#fff !important', marginBottom:'20px'}}>Enter email of account to reset password</h4>
                            {props.error && <HelpBlock>{props.error}</HelpBlock>}
                            <InputGroup style={{margin: '0 auto', width: '340px'}}>
                                <FormControl
                                    type="email"
                                    placeholder="Email"
                                    value={props.email}
                                    onChange={props.handleChange('email')}
                                />
                                {/* <FormControl type="email" placeholder="Enter email" /> */}
                                
                                <FormControl.Feedback />
                            </InputGroup>
                        </FormGroup>
                    </div>


                    <FormGroup
                        controlId="formBasicTextSubmit"
                        className="text-center margin-bottom-large"
                    >
                        <Button
                            type='submit'
                            className="btn btn-primary"
                            disabled={R.isEmpty(props.formIsValid)}
                        >
                            Submit
                        </Button>
                    </FormGroup>
                </form>
            </div>
        </div>
    </div>
)


// TODO: add css styling to component
class RequestPasswordResetForm extends React.Component {
    state = {}
    handleSubmit = (evt) => {
        evt.preventDefault();
        this.props.dispatch(submitResetRequest(this.props.email));
    }
    handleChange = (field, value) => (evt) => {
        this.props.dispatch({ type: 'SET_RESET_EMAIL', [field]: value || evt.target.value });
    }
    render() {
        return (
            <div className='container-fluid margin-top-medium'>
                {
                !this.props.success &&
                    <EnterEmailForm
                        {...this.props}
                        handleSubmit={this.handleSubmit}
                        handleChange={this.handleChange}
                    />
                }
                {
                this.props.success && 
                    <div className={'col-xs-4 col-xs-offset-4 text-center'}>
                        <h1 style={{color: '#B5812B'}}>Success!</h1>
                        <p style={{color: '#fff'}}>Password reset link emailed to account email</p>
                        <br /><br />
                        <p style={{color: '#fff'}}>If you did not receive an email <a href="/password-reset" style={{color: '#B5812B'}}>Click Here</a> to try again. Be sure to check your email's spam folder for this email.</p>
                        <a className="btn btn-lg btn-primary" href="/signup/login?account">Login</a>
                    </div>
                        
                }
                <div className="text-center">
                    <span className="copyright text-grey">&copy;Copyright. GigHR 2019</span>
                </div>
            </div>
        )
    }
}



const composeComponent = R.compose(
    withRouter,
    connect((props) => ({ ...props.password_reset_request }))
)

export default composeComponent(RequestPasswordResetForm);