import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap'

export const Home = () => {
    return (
        <div style={{textAlign: 'center', marginTop: 100}}>
            <h1>Site Preview</h1>
            <Link to="/signup/login">
                <Button bsSize='large' bsStyle='primary' style={{borderRadius:0}}>Go to Import Wizard</Button>
            </Link>
        </div>
    )
}
