import { connect } from 'react-redux'
//import { Redirect, Link } from 'react-router-dom';
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Redirect, Link } from 'react-router-dom'
import { ProgressBar, FormGroup, Radio, Button, ButtonGroup } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import WizardNavigation from  '../signup-wizard-nav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import LinkedinSDK from 'react-linkedin-sdk'
import _ from 'lodash'
import consulting from './consulting'
import { primary_experience, secondary_experience, technology_experience, industry_experience, functional_experience, contextual_experience } from './experience'
import { authUser, createUser, loginUser, logoutUser, loginWithLinkedIn, updateUser, updateField, toggleTag, updateSelected } from './actions'
import companySizes from './company_size'
import * as R from 'ramda'
// import './style.css'
import qs from 'query-string'
import { css } from '@emotion/core'
import { BounceLoader as Loader } from 'react-spinners'
import S3FileUpload from 'react-s3'
import { titles } from './titles'
import { RSA_PKCS1_OAEP_PADDING } from 'constants';
import { Micons } from '../../icons'
import Navigationbar from '../navigationbar';
import config from '../../config'

const S3config = {
    bucketName: 'gighr-files',
    dirName: '',
    region: 'us-east-1',
    accessKeyId: 'AKIAJR3IYKBOFNYSLYJQ',
    secretAccessKey: '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',
}

const uploadFileTypes = [
    { id:'resume', label:'Resume' },
    { id:'bio', label:'Bio' },
    { id: 'project_summaries', label: 'Project Summaries' },
    // { id:'work_sample', label: 'Work Sample' }
]

const pluckValues = selected => {
    const ids = selected.map(obj => obj.id)
    if (ids[ids.length - 1] === 'Not Applicable') return ['Not Applicable']
    else return ids.filter(id => id !== 'Not Applicable')
}

var t = null  //for file upload internal

class SignupWizard extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            tab: 'welcome',
            consultants: {},
            fileProgress: 3,
            showFileProgress: false,
            loginEmail: '',
            loginPassword: '',
            uploadFiles: [],
            updateFileProgress: false,
            showSignup: this.props.location.search === "?account" ? false : true,
        }

        if (!props.user._id) {
            this.state.fetching = true
            props.dispatch(authUser(() => {
                this.setState({ fetching: false })
            }, () => {
                if (props.history.location.pathname !== '/signup/login')
                    props.history.push('/signup/login')
            }))
        }

        this.updateFileProgress = this.updateFileProgress.bind(this)
    }



    componentDidMount(){
        const params = qs.parse(window.location.search);
        const { code } = params
        if(code !== undefined){
            const { origin, pathname } = window.location
            const redirect_uri = origin + pathname
            this.props.dispatch(loginWithLinkedIn({code, redirect_uri}))
                .then(result => {
                    this.setState({ code }, () => this.props.history.push('/signup/welcome'))
                })
        }
    }

    //faking file upload progress
    // updateFileProgress = (fileName, progress) => _.throttle(this.throttledUpdateFileProgress(fileName, progress), 5000)

    updateFileProgress(fileName, progress = null){
        if(!this.state.updateFileProgress)
            return

        const allFiles = this.state.uploadFiles
        
        let index = 0;
        for(let i = 0; i < allFiles.length; i++){
            if(allFiles[i].name === fileName)
                index = i
        }

        //const file = _.find(this.state.uploadFiles, { name })
        if(progress === null)
            progress = allFiles[index].progress
        
        if(progress >= 70){
            progress += 3
        } else if (progress >= 7){
            progress += 2
        } else if (progress >= 75){
            progress += 1
        } else if (progress >= 80){
            progress += 0.2
        } else if (progress >= 83){
            progress += 0.1
        } else if (progress >= 90){
            progress += 0
        } else {
            progress += 3
        }

        allFiles[index].progress = progress
        
        this.setState({uploadFiles: allFiles, [fileName]: progress}, () => {
            if( progress < 100 && allFiles[index].progress < 100){
                setTimeout(() => this.updateFileProgress(fileName, progress), 500)
            } else {
                this.setState({updateFileProgress: false})
            }
        })
        
    }

    handleUpdate(value, field){
        this.props.dispatch(updateField(field, value))
    }

    handleUpdateSelected(field, selected){
        this.props.dispatch(updateSelected(field, selected))
    }

    handleFileUpdate({ fileType, fileName, fileUrl, fileProgress }){
        if (!this.props.wizard.user.files) this.props.wizard.user.files = []
        const { files } = this.props.wizard.user
        const exists = _.find(files, { fileType }, null)
        let newFiles = null
        if(exists !== undefined && exists !== null){
            newFiles = _.map(files, file => {
                if(file.fileType === fileType)
                    return { fileType, fileName, fileUrl, fileProgress }
                else
                    return file
            })
            this.props.dispatch(updateField('files', newFiles))
        } else {
            files.push({ fileType, fileName, fileProgress }) 
            this.props.dispatch(updateField('files', files))
        }
    }

    handleRemoveFile(fileType){
        const { files } = this.props.wizard.user
        
        const newFiles = _.filter(files, file => {
            if(file.fileType !== fileType)
            return true
            else
            return false
        })
        this.props.dispatch(updateField('files', newFiles))
    }

    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    logout() {
        this.props.dispatch(logoutUser(() => {
            this.props.history.push('/signup/login?account')
        }))
    }

    handleNext(current, tab){
        const redir = path => {
            this.setState({ logInFeedback: '', signUpFeedback: '' })
            this.topFunction()
            this.props.history.push(path)
        }
        const fieldsExist = (fields, cb) => user => {
            return [ ...fields.filter(field => {
                const val = user[field]
                if (val === undefined) return true
                if (typeof val === 'string') return !val.length
                if (Array.isArray(val)) return !val.length
                return false
            }), ...(cb ? cb(user) : []) ]
        }
        const validation = {
            'welcome': fieldsExist([ 'firstName', 'lastName' ]),
            'consulting': fieldsExist([ 'current_ic', 'billable_work' ], user => {
                let broke = []
                if (user.current_ic) {
                    if (user.how_long_ic === undefined) broke.push('how_long_ic')
                } else {
                    if (user.considering_ic === undefined) broke.push('considering_ic')
                    if (user.fulltime_interest === undefined) broke.push('fulltime_interest')
                }
                if (user.billable_work) {
                    if (!user.consulting || !user.consulting.length) broke.push('consulting')
                    if (user.how_long_bc === undefined) broke.push('how_long_bc')
                }
                return broke
            }),
            'corporate': fieldsExist(['corporate_experience'], user => {
                let broke = []
                if (user.corporate_experience) {
                    broke = [ ...broke, ...fieldsExist(['company_size', 'internal_consulting', 'leadership_dev_prog'])(user) ]
                }
                return broke
            }),
            'expertise': fieldsExist(['primary_experience', 'secondary_experience', 'technology_experience']),
            'experience': fieldsExist(['industry_experience', 'functional_experience', 'contextual_experience']),
            'availability': fieldsExist(['travel', 'on_site', 'full_time'], user => {
                let broke = []
                if (!user.full_time) {
                    if (user.part_time === undefined) broke.push('part_time')
                    if (!user.part_time) {
                        if (user.available_date === undefined) broke.push('available_date')
                        else if (!user.available_date.length) broke.push('available_date')
                    }
                }
                return broke
            })
        }
        switch(current){
            case 'login':
                this.props.dispatch(createUser(this.props.wizard.user, redir, feedback => {
                    this.setState({ signUpFeedback: feedback })
                }))
                break;
            case 'welcome':
            case 'consulting':
            case 'corporate':
            case 'expertise':
            case 'experience':
            case 'availability':
                const brokeFields = validation[current](this.props.wizard.user)
                if (brokeFields.length) {
                    this.setState({brokeFields})
                    break
                }
            case 'background':
                this.props.dispatch(updateUser(this.props.wizard.user, tab))
                redir(`/signup/${tab}`)
                break
            default:
        }
    }

    handleSeeProfile() {
        const email = this.props.user._id ? this.props.user.email : this.props.wizard.user.email
        this.props.dispatch(updateUser({ email }, null, () => {
            this.props.history.push('/profile')
        }))
    }

    handleLogin() {
        const { loginEmail, loginPassword } = this.state

        if (!loginEmail.length) {
            this.setState({ logInFeedback: 'Please enter an email.' })
        } else if (!loginPassword.length) {
            this.setState({ logInFeedback: 'Please enter a password.' })
        } else {
            this.props.dispatch(loginUser({
                email: this.state.loginEmail, password: this.state.loginPassword,
                redir: path => {
                    this.setState({ logInFeedback: '', signUpFeedback: '' })
                    this.props.history.push(path)
                    this.topFunction()
                },
                cb: feedback => {
                    this.setState({ logInFeedback: feedback })
                }
            }))
        }
    }

    consultingTags(){
        var options = _.map(consulting, consultant => {
             return { id: consultant, label: consultant }
        })
        options.push({ id: 'Not Applicable', label: 'Not Applicable' })
        const selected = (this.props.wizard.user.consulting || []).map(_=>({id:_,label:_}));
        return (
            <Typeahead
                multiple={true}
                labelKey='label'
                options={options}
                onChange={selected => this.handleUpdateSelected('consulting', pluckValues(selected))}
                selected={selected}
                placeholder="Select all that apply or not applicable"
                disabled = {false}
            />
        )
    }

    primaryExperienceSelect(){
        const options = primary_experience.map(exp => ({ id: exp, label: exp }));
        const selected = (this.props.wizard.user.primary_experience || []).map(_=>({id:_,label:_}));
        return (
            <div className='typeahead-close-container' >
                <Typeahead
                    multiple={false}
                    labelKey='label'
                    options={options}
                    selected={selected}
                    onChange={selected => {
                        this.handleUpdateSelected('primary_experience', pluckValues(selected))
                        this.handleUpdateSelected('secondary_experience', [])
                        this.handleUpdateSelected('technology_experience', [])
                    }}
                    placeholder="Select one"
                    disabled = {false} />
                {this.props.wizard.user.primary_experience && this.props.wizard.user.primary_experience.length > 0 && (
                    <div className='typeahead-close'
                        onClick={() => {
                            this.handleUpdateSelected('primary_experience', [])
                        }} >
                        <Micons name="times" />
                    </div>
                )}
            </div>
        )
    }

    secondaryExperienceSelect(){
        const selected = this.props.wizard.user.primary_experience[0]
        const options = Object.keys(secondary_experience).filter(exp =>
            secondary_experience[exp].includes(selected) ||
            secondary_experience[exp].includes('All'))
            .map(exp => ({ id: exp, label: exp }))
        options.push({ id: 'Not Applicable', label: 'Not Applicable' })
        const selectedOptions = (this.props.wizard.user.secondary_experience || []).map(_=>({id:_,label:_}));

        return (
           <Typeahead
               multiple={true}
               labelKey='label'
               options={options}
               selected={selectedOptions}
               onChange={selected => this.handleUpdateSelected('secondary_experience', pluckValues(selected))}
               placeholder="Select all that apply or not applicable"
               disabled = {false}
           />
       )
    }

    fieldSelect({source, field, multiple}){
        var options = []
        _.each(_.flatten(source), exp => {
            if(typeof exp === "string")
                options.push({ id: exp, label: exp })
        })
        options.push({ id: 'Not Applicable', label: 'Not Applicable' })
        const selected = (this.props.wizard.user[field] || []).map(_=>({id:_,label:_}))

        return (
           <Typeahead
               multiple={multiple}
               labelKey='label'
               options={options}
               selected={selected}
               onChange={selected => this.handleUpdateSelected(field, pluckValues(selected))}
               placeholder= {multiple ? "Select all that apply or not applicable" : "Select one"}
               disabled = {false}
           />
       )
    }

    handleSignUp() {
        const { user } = this.props.wizard
        if (!user.email.length) {
            this.setState({ signUpFeedback: 'Please provide an email.' })
        } else if (!user.password.length) {
            this.setState({ signUpFeedback: 'Please provide a password.' })
        } else if (user.password !== user.passwordConfirm) {
            this.setState({ signUpFeedback: 'Password confirm must match password.' })
        } else {
            this.handleNext('login', 'welcome')
        }
    }

    render(){

        const tab = this.props.match.params.tab.toLowerCase()

        if (this.props.user._id) {
            if (this.props.user.approved) {
                return <Redirect to='/profile' />
            } else if (tab === 'login') {
                return <Redirect to={`/signup/${this.props.user.intakeTab || 'welcome'}`} />
            }
        } else if (tab !== 'login') {
            return <Redirect to='/signup/login' />
        }

        const { logInFeedback, signUpFeedback } = this.state

        const PasswordValidation = () => {
            const { password, passwordConfirm } = this.props.wizard.user
            if(password === undefined) return <div></div>

            const errors = {
                // not_username: { text: 'Cannot contain your name or email address.', status: 'pass' },
                min_chars: { text: 'Must have at least 8 characters.', status: 'fail' },
                min_symbols: { text: 'Must contain a number or symbol.', status: 'neutral' },
                match: { text: 'Passwords must match.', status: 'neutral' }
            }

            const icons = { pass: 'circle', fail: 'circle', neutral: 'circle' }

            if(password.length === 0) errors.min_chars.status = 'neutral'
            else if(password.length > 0 && password.length < 8) errors.min_chars.status = 'fail'
            else errors.min_chars.status = 'pass'

            if(password.length === 0) errors.min_symbols.status = 'neutral'
            else if(/^[a-zA-Z]*$/.test(password) !== false) errors.min_symbols.status = 'fail'
            else errors.min_symbols.status = 'pass'

            if(password.length === 0) errors.match.status = 'neutral'
            else if(password !== passwordConfirm) errors.match.status = 'fail'
            else errors.match.status = 'pass'

            return (
                _.map(errors, error => {
                    return (
                        <div key={error.text}>
                            <FontAwesomeIcon className={`icon ${error.status}`} icon={icons[error.status]} /> {error.text}
                        </div>
                    )
                })

            )
        }

        const MultiSelectTags = (props) => {
            const selected = this.props.wizard.user[props.field] || []
            return (
                <div>
                    {_.map(props.values, value => {
                        return <Button className={`radio-select ${selected.includes(value) ? 'active' : ''}`}
                                    key={value}
                                    onClick={() => this.props.dispatch(toggleTag(props.field, value))}
                                >{value}</Button>
                    })}
                </div>
            )
        }

        const Or = (props) => {
            return (
                <div style={{textAlign:'center', ...props.style}}>
                    <div style={{borderBottom: '1px solid #777', marginBottom: -11}}></div>
                    <div style={{margin:'auto', backgroundColor:'#000', width:'30px', textAlign:'center', color:"#777"}}>Or</div>
                </div>
            )
        }

        const RadioSelect = (props) => {
            const field_value = this.props.wizard.user[props.field]
            return (
                <div className="answer-options">
                    {_.map(props.values, value => {
                        if (value.label) {
                            return <Button className={`radio-select ${field_value === value.value ? 'active' : ''}`}
                                        key={value.label}
                                        onClick={() => this.handleUpdate(value.value, props.field)}
                                    >{value.label}</Button>
                        } else {
                            return <Button className={`radio-select ${field_value === value ? 'active' : ''}`}
                                        key={value}
                                        onClick={() => this.handleUpdate(value, props.field)}
                                    >{value}</Button>
                        }
                    })}
                </div>
            )
        }

        const { user } = this.props.wizard
        const redirect_uri = escape(window.location.href.replace("?account", ""))
        const authurl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${config.LINKEDIN_CLIENT_ID}&redirect_uri=${redirect_uri}&state=LASD83LK84374&scope=r_emailaddress%20r_liteprofile`;
        const loader_override = css`
            display: block;
            margin: 0 auto;
            border-color: red;
            margin-left: calc(50vw - 75px);
            margin-top: calc(50vh - 75px);
            position: absolute;
        `;

        const BtnNextIcon = () => <Micons name="chevron-right" fill="#fff" style={{ position: 'absolute', right:'10px', bottom:'5px'}} />
        const BtnBackIcon = () => <Micons name="chevron-left" fill="#eab327" style={{ position: 'absolute', left:'10px', bottom:'4px'}} />

        const BrokeFieldMarker = ({name}) => (
            <div style={{color:'red'}}>
                {this.state.brokeFields && this.state.brokeFields.includes(name) && (
                    ' * This field is required.'
                )}
            </div>
        )
        return (
            <div className="signup-wizard">
                {this.props.wizard !== undefined && this.props.wizard.pending === true &&
                    <div className="loader" style={{ position:'fixed', width:'100vw', height:'100vh', backgroundColor:'#000000a8', zIndex:10, top:0, left:0 }}>
                        <Loader
                            css={loader_override}
                            sizeUnit={"px"}
                            size={150}
                            color={'#fff'}
                            loading={true}
                            />
                    </div>
                }
                <div className='page-header'>
                    <div className="container">
                        {tab !== 'login' &&
                            <WizardNavigation active={ tab || 'welcome' } onClick={index => this.setState({active: index})} userData={user} />
                        }
                        <div className='title-icon'>{titles[tab].icon}</div>
                        {titles[tab].title}
                        {titles[tab].subtitle}
                    </div>
                </div>
                <div className="page-body clear-fix">
                    <div className="container">
                        {tab === 'login' &&
                            <div className="login-form">
                                {this.state.showSignup ? (
                                    <div className="login-container">
                                        <div className="col-md-6">
                                            <h3 className="text-center mb-5 bold-n-gold">New To GigHR?</h3>
                                            <p>Before you apply, here are a few things to consider to see if you’d be a match for our GigHR network.</p>
                                            <br />
                                            <label style={{color: '#C89514'}}>Our consultants tend to:</label>
                                            <ul>
                                                <li>Have been trained at the top Human Capital consultancies and world-class HR organizations or have a niche or specialty in high demand</li>
                                                <li>Stay up to date through advanced degrees, continued education or certifications in the latest tools and technologies</li>
                                                <li>Be committed and established Independent Consultants (or are looking for their first right gig to make the leap!)</li>
                                                <li>Have experience in scoping work, designing deliverables and implementing independently</li>
                                                <li>Thrive on solving hairy problems or being able to step in as an interim executive or to cover a leave</li>
                                                <li>Care deeply about client service and delivering results</li>
                                            </ul>
                                            <br />
                                            <p>While we’re using technology to help us, this is a human process and our goal is to get to know you as a person so that we can match your talents to meaningful work.</p>
                                            <br />
                                            <label style={{color: '#C89514'}}>Here’s what you can expect:</label>
                                            <ul>
                                                <li>Set aside just 10 minutes, for many it’s even less</li>
                                                <li>Save time and effort by signing in with LinkedIn (or have your resume handy)</li>
                                                <li>Hit submit and we’ll reach out for a quick conversation before we start sharing opportunities with you</li>
                                            </ul>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group text-center mt-10">
                                                <a href={authurl}>
                                                    <img src="/img/LINKEDIN---Active.png" style={{maxWidth: 215}}/>
                                                </a>
                                                <Or style={{margin: '30px auto 15px auto', width: 215}}/>
                                            </div>
                                            <span style={{color: 'red'}}>{signUpFeedback}</span>
                                            <div className="form-group">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="register-email">Email</label>
                                                    <input type="email" className="form-control" id="register-email"
                                                        value={user.email} onChange={e => this.handleUpdate(e.target.value, 'email')} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="register-password">Password</label>
                                                    <input type="password" className="form-control" id="register-password"
                                                        value={user.password} onChange={e => this.handleUpdate(e.target.value, 'password')} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="register-confirm-password">Confirm Password</label>
                                                    <input type="password" className="form-control" id="register-confirm-password"
                                                        value={user.passwordConfirm} onChange={e => this.handleUpdate(e.target.value, 'passwordConfirm')} />
                                                </div>
                                            </div>
                                            <div style={{marginTop: 10}}><PasswordValidation /></div>
                                            
                                            <div className="form-group">
                                                <div className="form-group col-md-12 text-center">
                                                    <div className="form-group col-md-12 text-center padding-top-large">
                                                        <Button bsStyle="primary" style={{minWidth: 215}} bsSize="large" onClick={this.handleSignUp.bind(this)}>Sign Up</Button>
                                                    </div>
                                                    <a style={{marginRight:'15px', cursor:'pointer'}} onClick={() => {this.setState({showSignup: false})}}>Log In</a>
                                                    <div style={{clear: 'both'}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="login-container">
                                        <div className="row">
                                            <div className="col-md-6 col-md-offset-3">
                                                <h3 className="text-center">Please log in with your LinkedIn Credentials or the Email and Password you used to sign up</h3>
                                                <div className="fomr-group text-center mt-10">
                                                    <a href={authurl}>
                                                        <img src="/img/LINKEDIN---Active.png" style={{maxWidth: 215}}/>
                                                    </a>
                                                    <Or style={{margin: '30px auto 15px auto', width: 215}}/>   
                                                </div>
                                                <span style={{color: 'red'}}>{logInFeedback}</span>
                                                <div className="form-group">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="login-email">Email</label>
                                                        <input type="email" className="form-control" id="login-email"
                                                            value={this.state.loginEmail} onChange={e => {
                                                                this.setState({loginEmail: e.target.value})
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="login-password">Password</label>
                                                        <input type="password" className="form-control" id="login-password"
                                                            value={this.state.loginPassword} onChange={e => this.setState({loginPassword: e.target.value})} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-group col-md-12 text-center">
                                                        <div className="form-group col-md-12 text-center padding-top-large">
                                                            <Button bsStyle="primary" style={{minWidth: 215}} bsSize="large" onClick={this.handleLogin.bind(this)}>Log In</Button>
                                                        </div>
                                                        <div>
                                                            <a style={{marginRight:'15px', cursor:'pointer'}} onClick={() => {this.setState({showSignup: true})}}>New to GigHR?</a>
                                                            <Link to="/password-reset">Forgot Password</Link>
                                                        </div>
                                                        <div style={{clear: 'both'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        <form>
                            {tab === 'welcome' &&
                                <React.Fragment>
                                    {/*<div className="form-group col-md-12 text-center margin-bottom-large padding-bottom-large">
                                        Already have an account? <a href="#">Log in here.</a>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-group col-md-12 text-center">
                                            <a href={authurl}>
                                                <img src="/img/LINKEDIN---Active.png" style={{maxWidth: 215}}/>
                                            </a>

                                            <Or style={{margin: '30px auto', width: 215}}/>

                                        </div>
                                    </div>*/}



                                    <div className="form-group">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="firstName">
                                                First Name
                                                <BrokeFieldMarker name='firstName' />
                                            </label>
                                            <input type="text" className="form-control" id="firstName" value={user.firstName} onChange={e => this.handleUpdate(e.target.value, 'firstName')} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="lastName">Last Name <BrokeFieldMarker name='lastName' /></label>
                                            <input type="text" className="form-control" id="lastName" value={user.lastName} onChange={e => this.handleUpdate(e.target.value, 'lastName')}  />
                                        </div>
                                    </div>
                                    {/*<div className="form-group col-md-12 padding-top-large">
                                        <label htmlFor="email">What's the best email to use? <small>(We promise we won't spam you!)</small></label>
                                        <input type="email" className="form-control" id="email" value={user.email} onChange={e => this.handleUpdate(e.target.value, 'email')}  />
                                    </div>*/}
                                    <div className="form-group col-md-12">
                                        <label htmlFor="business">
                                            Business Name
                                            <small> (If you have one)</small>
                                            <BrokeFieldMarker name='business' />
                                        </label>
                                        <input type="text" className="form-control" id="business" value={user.business} onChange={e => this.handleUpdate(e.target.value, 'business')}  />
                                    </div>
                                    {/*<div className="form-group">
                                        <div className="form-group col-md-6 padding-top-large">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" className="form-control" id="password" value={user.password} onChange={e => this.handleUpdate(e.target.value, 'password')}  />
                                            <div style={{marginTop: 10}}><PasswordValidation /></div>
                                        </div>
                                        <div className="form-group col-md-6 padding-top-large">
                                            <label htmlFor="passwordConfirm">Confirm Password</label>
                                            <input type="password" className="form-control" id="passwordConfirm" value={user.passwordConfirm} onChange={e => this.handleUpdate(e.target.value, 'passwordConfirm')}  />
                                        </div>
                                    </div>*/}
                                    <div className="form-group col-md-12 text-center padding-top-large">
                                        <Button bsStyle="primary" bsSize="large" style={{float: 'right'}} onClick={() => this.handleNext('welcome', 'consulting')}>Next<BtnNextIcon /></Button>
                                    </div>

                                </React.Fragment>
                            }
                            { tab === 'consulting' &&
                                <div className="spaced">
                                    <FormGroup>
                                        <label>Are you currently working as an Independent Consultant (IC)? <BrokeFieldMarker name='current_ic' /></label>
                                        <RadioSelect
                                            values={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                            field="current_ic" />
                                    </FormGroup>

                                    {user.current_ic === true &&
                                        <FormGroup>
                                            <label>How long have you been working as an IC? <BrokeFieldMarker name='how_long_ic' /></label>
                                            <RadioSelect className="radios" values={["< 2 years", "2-5 years", "> 5 years"]} field="how_long_ic" />
                                        </FormGroup>
                                    }
                                    {user.current_ic === false &&
                                        <React.Fragment>
                                            <FormGroup>
                                                <label>Are you considering becoming an IC in the next 6 months? <BrokeFieldMarker name='considering_ic' /></label>
                                                <RadioSelect
                                                    values={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                                    field="considering_ic" />
                                            </FormGroup>
                                            <FormGroup>
                                                <label>Are you interested in full-time opportunities? <BrokeFieldMarker name='fulltime_interest' /></label>
                                                <RadioSelect
                                                    values={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                                    field="fulltime_interest" />
                                            </FormGroup>
                                        </React.Fragment>
                                    }
                                    <FormGroup>
                                        <label>Have you worked for consulting firms doing billable work? <BrokeFieldMarker name='billable_work' /></label>
                                        <RadioSelect
                                            values={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                            field="billable_work" />
                                    </FormGroup>
                                    {user.billable_work === true &&
                                        <React.Fragment>
                                            <FormGroup>
                                                <label>Which consulting firms?  <BrokeFieldMarker name='consulting' /></label>
                                                <div className="answer-options">
                                                    <div>{this.consultingTags()}</div>
                                                    <div style={{marginTop: 5, marginLeft: 5}}>If None, please select or type None. If your firm is not listed, please select or type Other.</div>
                                                    <div style={{clear:'both'}} />
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <label>For how many years were you a billable consultant? <BrokeFieldMarker name='how_long_bc' /></label>
                                                <RadioSelect values={["< 2 years", "2-5 years", "5-10 years", "10-15 years", "> 15 years"]} field="how_long_bc" />
                                            </FormGroup>
                                        </React.Fragment>
                                    }
                                    <div className="row">
                                        <div className="form-group col-md-12 text-center padding-top-large">
                                            <Button bsStyle="inverted" bsSize="large" style={{float: 'left'}} onClick={() => this.handleNext('consulting', 'welcome')}><BtnBackIcon /> Back</Button>
                                            <Button bsStyle="primary" bsSize="large" style={{float: 'right'}} onClick={() => this.handleNext('consulting', 'corporate')}>Next<BtnNextIcon /></Button>
                                        </div>
                                    </div>
                                </div>
                            }
                            { tab === 'corporate' &&
                                <div className='spaced'>
                                    <FormGroup>
                                        <label>Do you have corporate experience? <BrokeFieldMarker name='corporate_experience' /></label>
                                        <RadioSelect
                                            values={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                            field="corporate_experience" />
                                    </FormGroup>

                                    {user.corporate_experience === true &&
                                        <React.Fragment>
                                            <FormGroup>
                                                <label>What size companies have you worked for? <BrokeFieldMarker name='company_size' /></label>
                                                <div className="answer-options">
                                                    <MultiSelectTags values={companySizes} field="company_size" />
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <label>Have you been part of an internal consulting function? <BrokeFieldMarker name='internal_consulting' /></label>
                                                <RadioSelect
                                                    values={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                                    field="internal_consulting" />
                                            </FormGroup>
                                            <FormGroup>
                                                <label>Have you participated in an HR Leadership Development Program? <BrokeFieldMarker name='leadership_dev_prog' /></label>
                                                <RadioSelect
                                                    values={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                                    field="leadership_dev_prog" />
                                            </FormGroup>
                                        </React.Fragment>
                                    }
                                    <div className="form-group col-md-12 text-center padding-top-large">
                                        <Button bsStyle="inverted" bsSize="large" style={{float: 'left'}} onClick={() => this.handleNext('corporate', 'consulting')}><BtnBackIcon />Back</Button>
                                        <Button bsStyle="primary" bsSize="large" style={{float: 'right'}} onClick={() => this.handleNext('corporate', 'expertise')}>Next<BtnNextIcon /></Button>
                                    </div>
                                </div>
                            }
                            { tab === 'expertise' &&
                                <div className='spaced'>
                                    <FormGroup>
                                        <div className="mb-5">
                                            <label>We realize you may have broad experiences, but if you had to choose just one area that is your "sweetest" spot, what would it be? <BrokeFieldMarker name='primary_experience' /></label>
                                            <div className="answer-options">
                                                {this.primaryExperienceSelect()}
                                            </div>
                                        </div>
                                    </FormGroup>

                                    {user.primary_experience !== undefined && user.primary_experience.length > 0 &&
                                        <React.Fragment>
                                            <FormGroup>
                                                <div className="mb-5">
                                                    <label>Within <span>{user.primary_experience[0]}</span>, what areas would you feel most comfortable independently delivering a project. <BrokeFieldMarker name='secondary_experience' /></label>
                                                    <div className="answer-options">
                                                        {this.secondaryExperienceSelect()}
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <div className="mb-5">
                                                    <label>Which technologies are you an expert in implementing or leading adoption in? <BrokeFieldMarker name='technology_experience' /></label>
                                                    <div className="answer-options">
                                                        {this.fieldSelect({source: technology_experience, field:'technology_experience', multiple: true})}
                                                        <div style={{clear:'both'}} />
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </React.Fragment>
                                    }
                                    <div className="form-group col-md-12 text-center padding-top-large">
                                        <Button bsStyle="inverted" bsSize="large" style={{float: 'left'}} onClick={() => this.handleNext('expertise', 'corporate')}><BtnBackIcon />Back</Button>
                                        <Button bsStyle="primary" bsSize="large" style={{float: 'right'}} onClick={() => this.handleNext('expertise', 'experience')}>Next<BtnNextIcon /></Button>
                                    </div>
                                </div>
                            }
                            { tab === 'experience' &&
                                <div className='spaced'>
                                    <FormGroup>
                                        <div className="mb-5">
                                            <label>Please tell us about your industry experience <BrokeFieldMarker name='industry_experience' /></label>
                                            <div className="answer-options">
                                                {this.fieldSelect({source: industry_experience, field:'industry_experience', multiple: true})}
                                            </div>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="mb-5">
                                            <label>Please tell us about the business functions you have supported <BrokeFieldMarker name='functional_experience' /></label>
                                            <div className="answer-options">
                                                {this.fieldSelect({source: functional_experience, field:'functional_experience', multiple: true})}
                                            </div>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="mb-5">
                                            <label>Do you have significant experience in any of the following business contexts or environments? <BrokeFieldMarker name='contextual_experience' /></label>
                                            <div className="answer-options">
                                                {this.fieldSelect({source: contextual_experience, field:'contextual_experience', multiple: true})}
                                            </div>
                                        </div>
                                    </FormGroup>

                                    <div className="form-group col-md-12 text-center padding-top-large">
                                        <Button bsStyle="inverted" bsSize="large" style={{float: 'left'}} onClick={() => this.handleNext('experience', 'expertise')}><BtnBackIcon />Back</Button>
                                        <Button bsStyle="primary" bsSize="large"style={{float: 'right'}} onClick={() => this.handleNext('experience', 'availability')}>Next<BtnNextIcon /></Button>
                                    </div>
                                </div>
                            }
                            { tab === 'availability' &&
                                <div className='spaced'>
                                    <FormGroup>
                                        <label>Please tell us about your ability to travel. <BrokeFieldMarker name='travel' /></label>
                                        <RadioSelect values={["No travel", "Up to 20%", "Up to 50%", "80% travel"]} field="travel" />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>If you are staffed locally, are you able to be on site? <BrokeFieldMarker name='on_site' /></label>
                                        <RadioSelect values={["Yes", "No", "Sometimes"]} field="on_site" />
                                    </FormGroup>

                                    {/*<FormGroup>
                                        <label>Are you currently available?</label>
                                        <MultiSelectTags field="current_availability" values={["Part time", "Fulltime"]} />
                                    </FormGroup>*/}

                                    <FormGroup>
                                        <label>Are you currently available full time? <BrokeFieldMarker name='full_time' /></label>
                                        <RadioSelect values={[{label:"Yes", value:true}, {label:"No", value:false}]} field="full_time" />
                                    </FormGroup>

                                    {user.full_time === false && (
                                        <FormGroup>
                                            <label>Are you currently available part time? <BrokeFieldMarker name='part_time' /></label>
                                            <RadioSelect values={[{label:"Yes", value:true}, {label:"No", value:false}]} field="part_time" />
                                        </FormGroup>
                                    )}

                                    {/* {user.part_time === true && (
                                        <FormGroup>
                                            <label>How many hours per week are you available to work? <BrokeFieldMarker name='part_time_hours' /></label>
                                            <div className="answer-options">
                                                <input type="number" className="form-control" id="part_time_hours" value={user.part_time_hours} onChange={e => this.handleUpdate(e.target.value, 'part_time_hours')}  />
                                            </div>
                                        </FormGroup>
                                    )} */}

                                    {user.full_time === false && user.part_time === false && (
                                        <FormGroup>
                                            <label>When do you think you will be available? <BrokeFieldMarker name='available_date' /></label>
                                            <input type="date" className="form-control" id="available_date" value={user.available_date} onChange={e => this.handleUpdate(e.target.value, 'available_date')}  />
                                        </FormGroup>
                                    )}

                                    {/* <FormGroup>
                                        <label>What is your zip code? <BrokeFieldMarker name='zip_code' /></label>
                                        <div className="answer-options">
                                            <input type="text" className="form-control" id="email" value={user.zip_code} onChange={e => this.handleUpdate(e.target.value, 'zip_code')}  />
                                        </div>
                                    </FormGroup> */}

                                    <FormGroup>
                                        <label>The fastest way to work is usually by phone, please share with us the best number to reach you. <BrokeFieldMarker name='phone_number' /></label>
                                        <input type="text" className="form-control" id="phone_number" value={user.phone_number} onChange={e => this.handleUpdate(e.target.value, 'phone_number')}  />
                                    </FormGroup>

                                    <div className="form-group col-md-12 text-center padding-top-large">
                                        <Button bsStyle="inverted" bsSize="large" style={{float: 'left'}} onClick={() => this.handleNext('availability', 'experience')}><BtnBackIcon />Back</Button>
                                        <Button bsStyle="primary" bsSize="large" style={{float: 'right'}} onClick={() => this.handleNext('availability', 'background')}>Next<BtnNextIcon /></Button>
                                    </div>
                                </div>
                            }
                            { tab === 'background' &&
                                <div className='spaced'>
                                    <FormGroup>
                                        <label style={{marginBottom:35}}>Please upload any documents you have handy that help give a complete picture of your background (you can always add more later):</label>
                                        <div style={{marginLeft:40}}>
                                            {
                                                _.map(uploadFileTypes, fileType => {
                                                    const upload = user.files !== undefined ? _.find(user.files, {fileType: fileType.id}, null) : null
                                                    return (
                                                        <div key={fileType.id}>
                                                            <div style={{fontWeight:'bold', marginBottom:5 }}>{ fileType.label }</div>
                                                            { upload === undefined || upload === null ? 
                                                                <React.Fragment>
                                                                    <input id={fileType.id} className="custom-file" ref={(ref) => { this.uploadInput = ref; }} type="file" title="" key={fileType.id || ''}
                                                                        onChange={e => {
                                                                            const file = e.target.files[0]
                                                                            this.handleFileUpdate({fileType: fileType.id, fileName: file.name, fileProgress: 3 })
                                                                            S3config['dirName'] = this.props.user._id
                                                                            S3FileUpload.uploadFile(e.target.files[0], S3config)
                                                                                .then(data => {
                                                                                    this.handleFileUpdate({fileType: fileType.id, fileName: file.name, fileUrl: data.location, fileProgress: 100 })
                                                                                })
                                                                                .catch(err => {
                                                                                    console.error("error:", err)
                                                                                })
                                                                        }}
                                                                    />
                                                                    <label htmlFor={fileType.id} className="btn btn-primary attachment" style={{marginBottom:20}}>Attach File</label>
                                                                </React.Fragment>
                                                                :
                                                                <div className="text-small text-lightgray uploaded-file" key={upload.name}>
                                                                    <ProgressBar now={upload.fileProgress} className="file-progress" label={upload.fileName} />
                                                                    <div onClick={() => this.handleRemoveFile(fileType.id)} style={{cursor:'pointer', display:'inline-block', marginLeft:'-33px', marginTop:'7px'}}><Micons name="times" /></div>
                                                                    <div style={{clear:"both"}} />
                                                                </div>
                                                            }
                                                            
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </FormGroup>

                                    <FormGroup>
                                        <label>Can you please share your LinkedIn profile with us?</label>
                                        <div className="answer-options">
                                            <input type="text" className="form-control" id="linkedin_url" placeholder="https://www.linkedin.com/in/username" value={user.linkedin_url} onChange={e => this.handleUpdate(e.target.value, 'linkedin_url')}  />
                                        </div>
                                    </FormGroup>

                                    <FormGroup>
                                        <label>Is there anything else you'd like to share with us that makes you unique or stand out from the crowd?</label>
                                        <div className="answer-options">
                                            <textarea className="form-control" id="more_info" value={user.more_info} onChange={e => this.handleUpdate(e.target.value, 'more_info')}  />
                                        </div>
                                    </FormGroup>

                                    <div className="form-group col-md-12 text-center padding-top-large">
                                        <Button bsStyle="inverted" bsSize="large" style={{float: 'left'}} onClick={() => this.handleNext('background', 'availability')}><BtnBackIcon />Back</Button>
                                        <Button bsStyle="primary" bsSize="large" style={{float: 'right'}} onClick={() => this.handleNext('background', 'thankyou')}>Next<BtnNextIcon /></Button>
                                        {/* <Button bsStyle="primary" bsSize="large" onClick={this.handleSeeProfile.bind(this)}>See Profile</Button> */}
                                    </div>
                                </div>
                            }
                            {tab === 'thankyou' && 
                                <React.Fragment>
                                    <div className="text-center">If you’d like to contact us in the interim, email us at: <a href="mailto:gighr@onpointhr.com">gighr@onpointhr.com</a></div>
                                    <div className="form-group col-md-12 text-center padding-top-large">
                                        {/* <Button style={{margin: '0 20px'}} bsStyle="primary" bsSize="large">Sign Out</Button> */}
                                        {/* <Button bsStyle="primary" bsSize="large" onClick={this.handleSeeProfile.bind(this)}>See Profile</Button> */}
                                    </div>
                                </React.Fragment>
                            }
                            { /* {tab !== 'login' && (
                                <div style={{textAlign: 'center', clear: 'both'}}>
                                    {/* <Button bsStyle="primary" bsSize="large" onClick={() => this.logout()}>Logout</Button> *//* }
                                    <a onClick={() => this.logout()}>Logout</a>
                                </div>
                            )} */ }
                        </form>
                    </div>
                </div>
            </div>
        )
    }
};

SignupWizard.contextTypes = {
    store: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  }
SignupWizard.propTypes = {
    dispatch: PropTypes.func.isRequired
};

const connectComponent = R.pipe(
    connect((state) => ({
      wizard: state.signup_wizard,
      user: state.user
    })),
    withRouter
  );

export default connectComponent(SignupWizard);
