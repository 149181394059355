import React from 'react'
import { ProgressBar, Modal, Button, Form, FormControl } from 'react-bootstrap'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faSquare, faCheckSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { updateUser, updateField } from '../actions.js'
import '../update-modal/style.css'
import S3FileUpload from 'react-s3'
import _ from 'lodash'
import { Micons } from '../../../icons'

const S3config = {
    bucketName: 'gighr-files',
    dirName: '',
    region: 'us-east-1',
    accessKeyId: 'AKIAJR3IYKBOFNYSLYJQ',
    secretAccessKey: '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',
}

class FilesModal extends React.Component {
  constructor(props, context) {
    super(props, context)
    const { user } = props
    this.state = { 
      update: {}, 
      loading: false,
      updateFileProgress: false
    }
    this.updateFileProgress = this.updateFileProgress.bind(this)
  }

  pushUpdate(field, value) {
    this.setState({ update: Object.assign({}, this.state.update, {
      [field.key]: [ ...(this.state.update[field.key] || this.props.user[field.key] || []), value ]
    }) })
  }

  save() {
    const email = this.props.user.email
    this.props.dispatch(updateUser(email, this.state.update, this.handleClose.bind(this)))
  }

  handleClose = () => {
    this.setState({ update: {}, loading: false })
    this.props.onClose()
  }

  handleShow = () => {

  }

  handleRemoveFile(fileType){
    const { files } = this.props.user
    const newFiles = _.filter(files, file => {
        if(file.fileType !== fileType)
        return true
        else
        return false
    })
    const update = { ...this.props.user, files: newFiles }
    this.setState({ update })
  }

  handleFileUpdate({ fileType, fileName, fileUrl, fileProgress }){
    const { files } = this.props.user
    const exists = _.find(files, { fileType }, null)
    let newFiles = null
    if(exists !== undefined && exists !== null){
        newFiles = _.map(files, file => {
            if(file.fileType === fileType)
                return { fileType, fileName, fileUrl, fileProgress }
            else
                return file
        })
        this.props.dispatch(updateField({...this.props.user, files: newFiles}))
        this.setState({ update: {...this.props.user, files: newFiles} })
    } else {
        files.push({ fileType, fileName, fileProgress }) 
        this.props.dispatch(updateField({...this.props.user, files}))
        this.setState({ update: {...this.props.user, files} })
    }  
  }

  updateFileProgress(fileName, progress = null){
    if(!this.state.updateFileProgress)
        return

    const allFiles = this.state.uploadFiles
    
    let index = 0;
    for(let i = 0; i < allFiles.length; i++){
        if(allFiles[i].name === fileName)
            index = i
    }

    if(progress === null)
        progress = allFiles[index].progress
    
    if(progress >= 70){
        progress += 3
    } else if (progress >= 7){
        progress += 2
    } else if (progress >= 75){
        progress += 1
    } else if (progress >= 80){
        progress += 0.2
    } else if (progress >= 83){
        progress += 0.1
    } else if (progress >= 90){
        progress += 0
    } else {
        progress += 3
    }

    allFiles[index].progress = progress
    
    this.setState({uploadFiles: allFiles, [fileName]: progress}, () => {
        if( progress < 100 && allFiles[index].progress < 100){
            setTimeout(() => this.updateFileProgress(fileName, progress), 500)
        } else {
            this.setState({updateFileProgress: false})
        }
    })
  }

  render() {
    const { category, show } = this.props
    const { updateMap, handleClose, handleShow } = this
    const { update } = this.state
    const user = Object.assign({}, this.props.user, update)

    const uploadFileTypes = [
      { id:'resume', label:'Resume' },
      { id:'bio', label:'Bio' },
      { id: 'project_summaries', label: 'Project Summaries' },
      // { id:'work_sample', label: 'Work Sample' }
    ]
  

    return ( <Modal show={show} onHide={this.handleClose} >
      <Modal.Body>
        <div className='title'>Files</div>
        {
            _.map(uploadFileTypes, fileType => {
                const upload = user.files !== undefined ? _.find(user.files, {fileType: fileType.id}, null) : null
                return (
                    <div key={fileType.id}>
                        <div style={{fontWeight:'bold', marginBottom:5 }}>{ fileType.label }</div>
                        { upload === undefined || upload === null ? 
                            <React.Fragment>
                                <input id={fileType.id} className="custom-file" ref={(ref) => { this.uploadInput = ref; }} type="file" title="" key={fileType.id || ''}
                                    onChange={e => {
                                        const file = e.target.files[0]
                                        this.handleFileUpdate({fileType: fileType.id, fileName: file.name, fileProgress: 3 })
                                        S3config['dirName'] = this.props.user._id
                                        S3FileUpload.uploadFile(e.target.files[0], S3config)
                                            .then(data => {
                                                this.handleFileUpdate({fileType: fileType.id, fileName: file.name, fileUrl: data.location, fileProgress: 100 })
                                            })
                                            .catch(err => {
                                                console.error("error:", err)
                                            })
                                    }}
                                />
                                <label htmlFor={fileType.id} className="btn btn-primary attachment" style={{marginBottom:20}}>Attach File</label>
                            </React.Fragment>
                            :
                            <div className="text-small text-lightgray uploaded-file" key={upload.name}>
                                <ProgressBar now={upload.fileProgress} className="file-progress" label={upload.fileName} />
                                <div onClick={() => this.handleRemoveFile(fileType.id)} style={{cursor:'pointer', display:'inline-block', marginLeft:'-33px', marginTop:'7px'}}><Micons name="times" /></div>
                                <div style={{clear:"both"}} />
                            </div>
                        }
                        
                    </div>
                )
            })
        }
        <div className='actions'>
          <Button onClick={this.handleClose.bind(this)} bsStyle="inverted">Close</Button>
          <Button disabled={this.state.loading} onClick={this.save.bind(this)} bsStyle="primary">
            {this.state.loading ? 'Loading...' : 'Save'}
          </Button>
        </div>
      </Modal.Body>
    </Modal> )
  }

}

export default FilesModal
