
const _ = require('lodash');

const initialState = { 
    username: '',
    password: '',
    isAuthenticated: false,
    session: {},
    user: {
      archive:null
    },
    success: null,
    pending: false,
    error: null
};


const loginReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_USERNAME_PASSWORD':
            const { type, ...rest } = action;
            return { ...state, ...rest };

        case 'SUBMIT_LOGIN_PENDING':
            return { ...state, pending: true }
        case 'SUBMIT_LOGIN_SUCCESS':
            return { ...state, success: true, pending: false, user: action.value, session: action.value, isAuthenticated: true }
        case 'SUBMIT_LOGIN_ERROR':
            return { ...state, error: action.error, success: false, pending: false }

        //dispatch(action.archiveselection())
        case 'ARCHIVE_SELECTION_PENDING':
          return {...state, pending: true, success: false, error:false}
        case 'ARCHIVE_SELECTION_SUCCESS':
          return {...state, pending: false ,success: true, error:false, user: action.value}
        case 'ARCHIVE_SELECTION_ERROR' :
          return {...state, pending: false, success: false, error: action.error}

        case 'SET_AUTH':
            return { ...state, ..._.omit(action, ['type']) }

        default:
            return state;
    }
}


export default loginReducer;