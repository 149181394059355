require('request');
const rp = require('request-promise').defaults({ baseUrl: window.location.origin, json: true });

export const submitLogin = (username, password) => async (dispatch) => {
    dispatch({ type: 'SUBMIT_LOGIN_PENDING' });
    try {
        const auth = await rp(`/api/users/login`, {
            method: 'POST',
            json: true,
            body: { username, password }
        });
        return dispatch({ type: 'SUBMIT_LOGIN_SUCCESS', value: auth.data });
    } catch(err){
        return dispatch({ type: 'SUBMIT_LOGIN_ERROR', error: err });
    }
};

export const archiveSelection = () => async (dispatch) => {
  dispatch({type: 'ARCHIVE_SELECTION_PENDING'});
    try {
        const archives = await rp('/api/archives', {
          method: 'PATCH',
          body: {}
        });
        return dispatch({type: 'ARCHIVE_SELECTION_SUCCESS', value: archives.data});

    } catch(err){
        return dispatch({type: 'ARCHIVE_SELECTION_ERROR', error:err});
    }
};