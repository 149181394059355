import React from 'react'
import { Micons } from '../../icons'

export const titles = {
    login: {
        icon: <img src="/img/gighr-arrowless.png" style={{maxWidth: 250}} />,
        title: <h1 style={{display:'none'}}>Welcome to <span>GigHR!</span></h1>,
        subtitle: <h3>We look forward to getting to know you. Let's get started.</h3>
    },
    welcome: {
        icon: <Micons name="welcome-hands" />,
        title: <h1>Welcome</h1>,
        subtitle: <h3>We look forward to getting to know you. Let's get started.</h3>
    },
    consulting: {
        icon: <Micons name="concentric-circles" />,
        title: <h1>Please tell us about your <span>consulting experience</span>.</h1>,
        subtitle: <h3>We value all of your consulting experience from big firms<br />to small boutiques and independent projects.</h3>
    },
    corporate: {
        icon: <Micons name="building" />,
        title: <h1>Do you have <span>corporate experience</span>, too?</h1>,
        subtitle: <h3>It helps us to know if you've worked internally as well.</h3>
    },
    expertise: {
        icon: <Micons name="bullseye" />,
        title: <h1>Everyone has a <span>sweet spot</span><br />What's yours?</h1>,
        subtitle: <h3>Your strengths help us identify the best roles for you.</h3>
    },
    experience: {
        icon: <Micons name="road" />,
        title: <h1>Which <span>Industries, Functions <br /> and Environments</span> do you have <br />the most experience in?</h1>,
        subtitle: <h3>This level of detail makes a great match even more likely.</h3>
    },
    availability: {
        icon: <Micons name="calendar" />,
        title: <h1>Let's talk <span>travel and availability</span>.</h1>,
        subtitle: <h3>Because location, commute and travel appetite all matter for getting the right fit.</h3>
    },
    background: {
        icon: <Micons name="book" />,
        title: <h1>This is a <span>great</span> summary!</h1>,
        subtitle: <h3>Please also share your resume and LinkedIn profile<br />so we can get a more complete view of your unique journey.</h3>
    },
    thankyou: {
        icon: <Micons name="smile" />,
        title: <h1>We are <span>delighted</span> you introduced yourself.</h1>,
        subtitle: <h3>Thank you for signing up for GigHR and sharing your background and expertise.<br /><br />We'll review your profile and if we have a near-term gig, we'll be in touch quickly.<br /><br />If your background aligns with gigs we regularly place, we'll also be in touch to get to know you more.<br /><br />If we think we're less likely to have gigs that play to your strengths, we'll let you know that too.</h3>
    }
}
