import React from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormGroup, FormControl, Button, InputGroup } from 'react-bootstrap';
import { verifyResetToken, submitNewPassword }  from './actions';


const IconAddon = ({ className }) => <InputGroup.Addon><i className={className}/></InputGroup.Addon>;

class PasswordResetForm extends React.Component {
    state = {}

    componentDidMount(){
        this.props.dispatch(verifyResetToken(this.props.match.params.reset_token));
    }
    handleSubmit = (evt) => {
        evt.preventDefault();
        this.props.dispatch(submitNewPassword(this.props.password, this.props.passwordConfirm, this.props.match.params.reset_token));
    }
    handleChange = (field, value) => (evt) => {
        this.props.dispatch({ type: 'SET_RESET_PASSWORD', [field]: value || evt.target.value });
    }
    render() {
        
        if (this.props.resetSuccess){
            return (<Redirect to={'/signup/login?account'} />)
        }

        return (
            <div className="container" style={{color:'#fff'}}>
                <div className="row">
                    <div className='' style={{width:'50%', margin:'auto'}}>
                        <form className='form-signin' onSubmit={this.handleSubmit}>
                            {
                                this.props.tokenValid && 
                                    <div className="col-xs-10 col-xs-offset-1 login-inputs">
                                        <FormGroup 
                                            controlId="formBasicTextPassword"
                                        >
                                            <h1 className='text-center highlight'>GigHR</h1>
                                            <h4 className='text-center' style={{color:'#fff !important', marginBottom:'20px'}}>Set New Password</h4>
                            
                                            <InputGroup>
                                                <FormControl 
                                                    type="password" 
                                                    placeholder="Password" 
                                                    value={this.props.password}
                                                    onChange={this.handleChange('password')}
                                                />
                                                
                                                <IconAddon className="fas fa-check"/>
                                                <FormControl.Feedback />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup 
                                            controlId="formBasicTextPasswordConfirm"
                                        >
                                            <InputGroup>
                                                <FormControl 
                                                    type="password" 
                                                    placeholder="Confirm Password" 
                                                    value={this.props.passwordConfirm} 
                                                    onChange={this.handleChange('passwordConfirm')} 
                                                />

                                                <IconAddon className="fas fa-check"/>
                                                <FormControl.Feedback />
                                            </InputGroup>
                                        </FormGroup>
                                    

                                        <FormGroup 
                                        controlId="formBasicTextSubmit" 
                                        className="text-center margin-bottom-large"
                                        >
                                        <Button 
                                            type='submit' 
                                            className="btn btn-primary margin-vert-zero"
                                            disabled={!this.props.formIsValid} 
                                        >
                                            Submit
                                        </Button>
                                        </FormGroup>
                                    </div>
                            }
                            
                            {
                                !this.props.tokenValid && <h2 style={{textAlign:'center'}}>{this.props.error}</h2>
                            }


                            
                        </form>
                    </div>
                </div>
                <span className="copyright text-grey">&copy;Copyright. GigHR 2019</span>
            </div>
        )
    }
}



export default connect((props) => ({ ...props.password_reset_set_password }))(PasswordResetForm);