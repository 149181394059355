import React, { Component } from 'react'
import { asyncContainer, Typeahead } from 'react-bootstrap-typeahead'
import PlacesAutocomplete from 'react-places-autocomplete'
const AsyncTypeahead = asyncContainer(Typeahead)

class CityInput extends Component {
  constructor(props, context) {
    super(props, context)
    this.data = { options: [] }
  }

  render() {
    const { value, onChange } = this.props

    return ( <PlacesAutocomplete
      value={value}
      onChange={value => onChange(value)}
      onSelect={value => onChange(value)}
      searchOptions={{ types: ['(cities)'] }} >

      {({ loading, suggestions, getInputProps, getSuggestionItemProps }) => {

        const inputProps = getInputProps({ placeholder: 'Enter a city...', className: 'places-autocomplete' })

        this.data.options = suggestions.map(suggestion => ({
          id: suggestion.id,
          label: suggestion.description,
          suggestion
        }))

        return ( <AsyncTypeahead
          defaultInputValue={inputProps.value}
          isLoading={loading}
          onChange={selections => {
            if (selections.length) {
              const itemProps = getSuggestionItemProps(selections[0].suggestion, { className: 'places-autocomplete-item' })
              itemProps.onClick()
            }
          }}
          placeholder={inputProps.placeholder}
          onInputChange={(value, e) => { inputProps.onChange(e) }}
          onSearch={value => { inputProps.onChange({target: {value}}) }}
          filterBy={()=>true}
          labelKey='label'
          options={this.data.options} /> )
      }}
    </PlacesAutocomplete> )
  }
}

export default CityInput