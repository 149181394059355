const rp = require('request-promise')
    .defaults({ baseUrl: window.location.origin, json: true });


/**
 * Submit request for password-reset
 * @param {*} email 
 */
export const submitResetRequest = (email) => async (dispatch) => {
    dispatch({ type: 'SUBMIT_RESET_PASSWORD_EMAIL_PENDING' });
    try {
        const reqResetResponse = await rp(`/api/users/password-reset`, {
            method: 'POST',
            json: true,
            body: { email }
        });
        return dispatch({ type: 'SUBMIT_RESET_PASSWORD_EMAIL_SUCCESS', value: reqResetResponse.data });
    } catch(err){
        return dispatch({ 
            type: 'SUBMIT_RESET_PASSWORD_EMAIL_ERROR', 
            error: err.error
        });
    }
};
