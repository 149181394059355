require('request');
const rp = require('request-promise').defaults({ baseUrl: window.location.origin, json: true });

export const submitLogout = () => async (dispatch) => {
    dispatch({ type: 'SUBMIT_LOGOUT_PENDING' });
    try {
        const auth = await rp(`/api/users/logout`, {
            method: 'GET',
        });
        return dispatch({ type: 'SUBMIT_LOGOUT_SUCCESS', value: auth.data });
    } catch(err){
        return dispatch({ type: 'SUBMIT_LOGOUT_ERROR', error: err });
    }
};


export const forceLogout = () => async (dispatch) => {
    dispatch('')
}

