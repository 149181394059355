import React from 'react'
import PropTypes from 'prop-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { setPropTypes, withContext, lifecycle, toClass, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import * as R from 'ramda';
import SignupWizard from './feature-components/signup-wizard';
import ProfileManager from './feature-components/profile-manager';
import Navigationbar from './feature-components/navigationbar';
import Login from './feature-components/login';
import actions from './actions';
import { Home } from './feature-components/home'
import AdminHome from './feature-components/admin-home';
import { RequestPasswordResetForm, SetNewPasswordForm } from './feature-components/password-reset';
import './stylesheets/main.scss';


library.add(faCircle);

// Wrap <Route /> component. Check auth status, redirect if not logged in
const ProtectedRouteBase = R.ifElse(
  R.propEq('isAuthenticated', false),
  (props) => <Redirect to={{ pathname: '/signup/login?account', state: { from: props.location }}}/>,
  (props) => <Route {...props} />
);

const buildProtectedRoute = R.pipe(
  // connect((state) => ({ isAuthenticated: state.signup_wizard.user.isAuthenticated || false, role: state.signup_wizard.user.role || "user" })),
  connect((state) => ({ isAuthenticated: true, role: "admin" })), //temp for dev purposes
  withRouter,
  setPropTypes({ isAuthenticated: PropTypes.bool })
);

const ProtectedRoute = buildProtectedRoute(ProtectedRouteBase);



const AppBase = (props) => {
  //const currentReference = props.location.pathname.split('/')[1];
  //const appClass = (props.location.pathname.includes('login')) ? 'show-background' : ''
  console.log("appBase:", props)
  return (
    <div className={`App`}>
      { props.history.location.pathname === '/' && (
        <Redirect from='/' to='/signup/login' />
      ) }
      <Navigationbar />
      <main>
        <Switch>
          <Route path='/password-reset' exact component={RequestPasswordResetForm} />
          <Route path='/password-reset/:reset_token' exact component={SetNewPasswordForm}/> */}
          <Route path='/signup/:tab' component={SignupWizard} />
          <Route path='/profile/:_id?' component={ProfileManager} />
          <ProtectedRoute path='/admin-home' component={AdminHome}/>
        </Switch>
      </main>
    </div>
  );
};


const composeComponent = R.pipe(
  lifecycle({
    componentDidMount(){
      //this.props.dispatch(actions.checkSession());
    }
  }),
  connect(R.identity),
  withRouter,
  withContext( { actions: PropTypes.object }, R.always({ actions })),
  setPropTypes({ dispatch: PropTypes.func }),
  setDisplayName('App'),
  toClass
);

// const composeComponent = R.pipe(
//   withRouter,
//   withContext( { actions: PropTypes.object }, R.always({ actions })),
//   setPropTypes({ dispatch: PropTypes.func }),
//   setDisplayName('App'),
//   toClass
// );

export default composeComponent(AppBase);
