
// const _ = require('lodash');

const initialState = { 
};


const logoutReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SUBMIT_LOGOUT_PENDING':
            return { ...state, pending: true }
        case 'SUBMIT_LOGOUT_SUCCESS':
            return {} //empty state
        case 'SUBMIT_LOGOUT_ERROR':
            return { ...state, error: action.error, success: false, pending: false }
        default:
            return state;
    }
}


export default logoutReducer;