const rp = require('request-promise').defaults({ baseUrl: window.location.origin, json: true });

const actions = window.actions = require.context('./feature-components', true, /actions\.js$/)
    .keys()
    .reduce((acc, key) => {
        const mod = require(`./feature-components/${key.replace('./', '')}`);
        return { ...acc, ...mod };
    }, {});

// Make request to /api/session to check if session is still valid 
const checkSession = () => async (dispatch) => {
    console.log("check session")
    try {
        const session = await rp('/api/users/session');
        console.log("session OK")
        return dispatch({ type: 'SET_AUTH', isAuthenticated: true, user: session.data });
    } catch (err){
        console.log("session BAD")
        // causes a redirect to login page
        dispatch({ type: 'SET_AUTH', isAuthenticated: false })
    }
};

export default { ...actions, checkSession };