const rp = require('request-promise').defaults({ baseUrl: window.location.origin, json: true });

export const logoutUser = cb => async dispatch => {
    dispatch({ type: 'LOGOUT_USER_PENDING' })
    rp(`/api/users/logout`, { method: 'GET' })
      .then(response => {
          if (!response.success) return dispatch({ type: 'LOGOUT_USER_ERROR', value: response })
          dispatch({ type: 'LOGOUT_USER_SUCCESS' })
          cb()
      })
      .catch(err => dispatch({ type: 'LOGOUT_USER_ERROR', value: err }))
}